/* tslint:disable */
/* eslint-disable */
/**
 * Ory Hydra API
 * Documentation for all of Ory Hydra\'s APIs.
 *
 * The version of the OpenAPI document: v2.2.1
 * Contact: hi@ory.sh
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, BaseAPI, operationServerMap } from './base';
/**
 * JwkApi - axios parameter creator
 * @export
 */
export const JwkApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * This endpoint is capable of generating JSON Web Key Sets for you. There a different strategies available, such as symmetric cryptographic keys (HS256, HS512) and asymetric cryptographic keys (RS256, ECDSA). If the specified JSON Web Key Set does not exist, it will be created.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Create JSON Web Key
         * @param {string} set The JSON Web Key Set ID
         * @param {CreateJsonWebKeySet} createJsonWebKeySet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJsonWebKeySet: (set_1, createJsonWebKeySet_1, ...args_1) => __awaiter(this, [set_1, createJsonWebKeySet_1, ...args_1], void 0, function* (set, createJsonWebKeySet, options = {}) {
            // verify required parameter 'set' is not null or undefined
            assertParamExists('createJsonWebKeySet', 'set', set);
            // verify required parameter 'createJsonWebKeySet' is not null or undefined
            assertParamExists('createJsonWebKeySet', 'createJsonWebKeySet', createJsonWebKeySet);
            const localVarPath = `/admin/keys/{set}`
                .replace(`{${"set"}}`, encodeURIComponent(String(set)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(createJsonWebKeySet, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use this endpoint to delete a single JSON Web Key.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Delete JSON Web Key
         * @param {string} set The JSON Web Key Set
         * @param {string} kid The JSON Web Key ID (kid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJsonWebKey: (set_1, kid_1, ...args_1) => __awaiter(this, [set_1, kid_1, ...args_1], void 0, function* (set, kid, options = {}) {
            // verify required parameter 'set' is not null or undefined
            assertParamExists('deleteJsonWebKey', 'set', set);
            // verify required parameter 'kid' is not null or undefined
            assertParamExists('deleteJsonWebKey', 'kid', kid);
            const localVarPath = `/admin/keys/{set}/{kid}`
                .replace(`{${"set"}}`, encodeURIComponent(String(set)))
                .replace(`{${"kid"}}`, encodeURIComponent(String(kid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use this endpoint to delete a complete JSON Web Key Set and all the keys in that set.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Delete JSON Web Key Set
         * @param {string} set The JSON Web Key Set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJsonWebKeySet: (set_1, ...args_1) => __awaiter(this, [set_1, ...args_1], void 0, function* (set, options = {}) {
            // verify required parameter 'set' is not null or undefined
            assertParamExists('deleteJsonWebKeySet', 'set', set);
            const localVarPath = `/admin/keys/{set}`
                .replace(`{${"set"}}`, encodeURIComponent(String(set)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint returns a singular JSON Web Key contained in a set. It is identified by the set and the specific key ID (kid).
         * @summary Get JSON Web Key
         * @param {string} set JSON Web Key Set ID
         * @param {string} kid JSON Web Key ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonWebKey: (set_1, kid_1, ...args_1) => __awaiter(this, [set_1, kid_1, ...args_1], void 0, function* (set, kid, options = {}) {
            // verify required parameter 'set' is not null or undefined
            assertParamExists('getJsonWebKey', 'set', set);
            // verify required parameter 'kid' is not null or undefined
            assertParamExists('getJsonWebKey', 'kid', kid);
            const localVarPath = `/admin/keys/{set}/{kid}`
                .replace(`{${"set"}}`, encodeURIComponent(String(set)))
                .replace(`{${"kid"}}`, encodeURIComponent(String(kid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint can be used to retrieve JWK Sets stored in ORY Hydra.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Retrieve a JSON Web Key Set
         * @param {string} set JSON Web Key Set ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonWebKeySet: (set_1, ...args_1) => __awaiter(this, [set_1, ...args_1], void 0, function* (set, options = {}) {
            // verify required parameter 'set' is not null or undefined
            assertParamExists('getJsonWebKeySet', 'set', set);
            const localVarPath = `/admin/keys/{set}`
                .replace(`{${"set"}}`, encodeURIComponent(String(set)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use this method if you do not want to let Hydra generate the JWKs for you, but instead save your own.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Set JSON Web Key
         * @param {string} set The JSON Web Key Set ID
         * @param {string} kid JSON Web Key ID
         * @param {JsonWebKey} [jsonWebKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJsonWebKey: (set_1, kid_1, jsonWebKey_1, ...args_1) => __awaiter(this, [set_1, kid_1, jsonWebKey_1, ...args_1], void 0, function* (set, kid, jsonWebKey, options = {}) {
            // verify required parameter 'set' is not null or undefined
            assertParamExists('setJsonWebKey', 'set', set);
            // verify required parameter 'kid' is not null or undefined
            assertParamExists('setJsonWebKey', 'kid', kid);
            const localVarPath = `/admin/keys/{set}/{kid}`
                .replace(`{${"set"}}`, encodeURIComponent(String(set)))
                .replace(`{${"kid"}}`, encodeURIComponent(String(kid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(jsonWebKey, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use this method if you do not want to let Hydra generate the JWKs for you, but instead save your own.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Update a JSON Web Key Set
         * @param {string} set The JSON Web Key Set ID
         * @param {JsonWebKeySet} [jsonWebKeySet]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJsonWebKeySet: (set_1, jsonWebKeySet_1, ...args_1) => __awaiter(this, [set_1, jsonWebKeySet_1, ...args_1], void 0, function* (set, jsonWebKeySet, options = {}) {
            // verify required parameter 'set' is not null or undefined
            assertParamExists('setJsonWebKeySet', 'set', set);
            const localVarPath = `/admin/keys/{set}`
                .replace(`{${"set"}}`, encodeURIComponent(String(set)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(jsonWebKeySet, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * JwkApi - functional programming interface
 * @export
 */
export const JwkApiFp = function (configuration) {
    const localVarAxiosParamCreator = JwkApiAxiosParamCreator(configuration);
    return {
        /**
         * This endpoint is capable of generating JSON Web Key Sets for you. There a different strategies available, such as symmetric cryptographic keys (HS256, HS512) and asymetric cryptographic keys (RS256, ECDSA). If the specified JSON Web Key Set does not exist, it will be created.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Create JSON Web Key
         * @param {string} set The JSON Web Key Set ID
         * @param {CreateJsonWebKeySet} createJsonWebKeySet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJsonWebKeySet(set, createJsonWebKeySet, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createJsonWebKeySet(set, createJsonWebKeySet, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['JwkApi.createJsonWebKeySet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use this endpoint to delete a single JSON Web Key.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Delete JSON Web Key
         * @param {string} set The JSON Web Key Set
         * @param {string} kid The JSON Web Key ID (kid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJsonWebKey(set, kid, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteJsonWebKey(set, kid, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['JwkApi.deleteJsonWebKey']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use this endpoint to delete a complete JSON Web Key Set and all the keys in that set.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Delete JSON Web Key Set
         * @param {string} set The JSON Web Key Set
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJsonWebKeySet(set, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteJsonWebKeySet(set, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['JwkApi.deleteJsonWebKeySet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint returns a singular JSON Web Key contained in a set. It is identified by the set and the specific key ID (kid).
         * @summary Get JSON Web Key
         * @param {string} set JSON Web Key Set ID
         * @param {string} kid JSON Web Key ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonWebKey(set, kid, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getJsonWebKey(set, kid, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['JwkApi.getJsonWebKey']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint can be used to retrieve JWK Sets stored in ORY Hydra.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Retrieve a JSON Web Key Set
         * @param {string} set JSON Web Key Set ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonWebKeySet(set, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getJsonWebKeySet(set, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['JwkApi.getJsonWebKeySet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use this method if you do not want to let Hydra generate the JWKs for you, but instead save your own.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Set JSON Web Key
         * @param {string} set The JSON Web Key Set ID
         * @param {string} kid JSON Web Key ID
         * @param {JsonWebKey} [jsonWebKey]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJsonWebKey(set, kid, jsonWebKey, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setJsonWebKey(set, kid, jsonWebKey, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['JwkApi.setJsonWebKey']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use this method if you do not want to let Hydra generate the JWKs for you, but instead save your own.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Update a JSON Web Key Set
         * @param {string} set The JSON Web Key Set ID
         * @param {JsonWebKeySet} [jsonWebKeySet]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJsonWebKeySet(set, jsonWebKeySet, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setJsonWebKeySet(set, jsonWebKeySet, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['JwkApi.setJsonWebKeySet']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * JwkApi - factory interface
 * @export
 */
export const JwkApiFactory = function (configuration, basePath, axios) {
    const localVarFp = JwkApiFp(configuration);
    return {
        /**
         * This endpoint is capable of generating JSON Web Key Sets for you. There a different strategies available, such as symmetric cryptographic keys (HS256, HS512) and asymetric cryptographic keys (RS256, ECDSA). If the specified JSON Web Key Set does not exist, it will be created.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Create JSON Web Key
         * @param {JwkApiCreateJsonWebKeySetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createJsonWebKeySet(requestParameters, options) {
            return localVarFp.createJsonWebKeySet(requestParameters.set, requestParameters.createJsonWebKeySet, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete a single JSON Web Key.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Delete JSON Web Key
         * @param {JwkApiDeleteJsonWebKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJsonWebKey(requestParameters, options) {
            return localVarFp.deleteJsonWebKey(requestParameters.set, requestParameters.kid, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete a complete JSON Web Key Set and all the keys in that set.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Delete JSON Web Key Set
         * @param {JwkApiDeleteJsonWebKeySetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJsonWebKeySet(requestParameters, options) {
            return localVarFp.deleteJsonWebKeySet(requestParameters.set, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a singular JSON Web Key contained in a set. It is identified by the set and the specific key ID (kid).
         * @summary Get JSON Web Key
         * @param {JwkApiGetJsonWebKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonWebKey(requestParameters, options) {
            return localVarFp.getJsonWebKey(requestParameters.set, requestParameters.kid, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint can be used to retrieve JWK Sets stored in ORY Hydra.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Retrieve a JSON Web Key Set
         * @param {JwkApiGetJsonWebKeySetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJsonWebKeySet(requestParameters, options) {
            return localVarFp.getJsonWebKeySet(requestParameters.set, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this method if you do not want to let Hydra generate the JWKs for you, but instead save your own.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Set JSON Web Key
         * @param {JwkApiSetJsonWebKeyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJsonWebKey(requestParameters, options) {
            return localVarFp.setJsonWebKey(requestParameters.set, requestParameters.kid, requestParameters.jsonWebKey, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this method if you do not want to let Hydra generate the JWKs for you, but instead save your own.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
         * @summary Update a JSON Web Key Set
         * @param {JwkApiSetJsonWebKeySetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setJsonWebKeySet(requestParameters, options) {
            return localVarFp.setJsonWebKeySet(requestParameters.set, requestParameters.jsonWebKeySet, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * JwkApi - object-oriented interface
 * @export
 * @class JwkApi
 * @extends {BaseAPI}
 */
export class JwkApi extends BaseAPI {
    /**
     * This endpoint is capable of generating JSON Web Key Sets for you. There a different strategies available, such as symmetric cryptographic keys (HS256, HS512) and asymetric cryptographic keys (RS256, ECDSA). If the specified JSON Web Key Set does not exist, it will be created.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
     * @summary Create JSON Web Key
     * @param {JwkApiCreateJsonWebKeySetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwkApi
     */
    createJsonWebKeySet(requestParameters, options) {
        return JwkApiFp(this.configuration).createJsonWebKeySet(requestParameters.set, requestParameters.createJsonWebKeySet, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use this endpoint to delete a single JSON Web Key.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
     * @summary Delete JSON Web Key
     * @param {JwkApiDeleteJsonWebKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwkApi
     */
    deleteJsonWebKey(requestParameters, options) {
        return JwkApiFp(this.configuration).deleteJsonWebKey(requestParameters.set, requestParameters.kid, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use this endpoint to delete a complete JSON Web Key Set and all the keys in that set.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
     * @summary Delete JSON Web Key Set
     * @param {JwkApiDeleteJsonWebKeySetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwkApi
     */
    deleteJsonWebKeySet(requestParameters, options) {
        return JwkApiFp(this.configuration).deleteJsonWebKeySet(requestParameters.set, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint returns a singular JSON Web Key contained in a set. It is identified by the set and the specific key ID (kid).
     * @summary Get JSON Web Key
     * @param {JwkApiGetJsonWebKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwkApi
     */
    getJsonWebKey(requestParameters, options) {
        return JwkApiFp(this.configuration).getJsonWebKey(requestParameters.set, requestParameters.kid, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint can be used to retrieve JWK Sets stored in ORY Hydra.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
     * @summary Retrieve a JSON Web Key Set
     * @param {JwkApiGetJsonWebKeySetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwkApi
     */
    getJsonWebKeySet(requestParameters, options) {
        return JwkApiFp(this.configuration).getJsonWebKeySet(requestParameters.set, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use this method if you do not want to let Hydra generate the JWKs for you, but instead save your own.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
     * @summary Set JSON Web Key
     * @param {JwkApiSetJsonWebKeyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwkApi
     */
    setJsonWebKey(requestParameters, options) {
        return JwkApiFp(this.configuration).setJsonWebKey(requestParameters.set, requestParameters.kid, requestParameters.jsonWebKey, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use this method if you do not want to let Hydra generate the JWKs for you, but instead save your own.  A JSON Web Key (JWK) is a JavaScript Object Notation (JSON) data structure that represents a cryptographic key. A JWK Set is a JSON data structure that represents a set of JWKs. A JSON Web Key is identified by its set and key id. ORY Hydra uses this functionality to store cryptographic keys used for TLS and JSON Web Tokens (such as OpenID Connect ID tokens), and allows storing user-defined keys as well.
     * @summary Update a JSON Web Key Set
     * @param {JwkApiSetJsonWebKeySetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JwkApi
     */
    setJsonWebKeySet(requestParameters, options) {
        return JwkApiFp(this.configuration).setJsonWebKeySet(requestParameters.set, requestParameters.jsonWebKeySet, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * MetadataApi - axios parameter creator
 * @export
 */
export const MetadataApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * This endpoint returns the version of Ory Hydra.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of this service, the version will never refer to the cluster state, only to a single instance.
         * @summary Return Running Software Version.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: (...args_1) => __awaiter(this, [...args_1], void 0, function* (options = {}) {
            const localVarPath = `/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint returns a HTTP 200 status code when Ory Hydra is accepting incoming HTTP requests. This status does currently not include checks whether the database connection is working.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of this service, the health status will never refer to the cluster state, only to a single instance.
         * @summary Check HTTP Server Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAlive: (...args_1) => __awaiter(this, [...args_1], void 0, function* (options = {}) {
            const localVarPath = `/health/alive`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint returns a HTTP 200 status code when Ory Hydra is up running and the environment dependencies (e.g. the database) are responsive as well.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of Ory Hydra, the health status will never refer to the cluster state, only to a single instance.
         * @summary Check HTTP Server and Database Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isReady: (...args_1) => __awaiter(this, [...args_1], void 0, function* (options = {}) {
            const localVarPath = `/health/ready`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * MetadataApi - functional programming interface
 * @export
 */
export const MetadataApiFp = function (configuration) {
    const localVarAxiosParamCreator = MetadataApiAxiosParamCreator(configuration);
    return {
        /**
         * This endpoint returns the version of Ory Hydra.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of this service, the version will never refer to the cluster state, only to a single instance.
         * @summary Return Running Software Version.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getVersion(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MetadataApi.getVersion']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint returns a HTTP 200 status code when Ory Hydra is accepting incoming HTTP requests. This status does currently not include checks whether the database connection is working.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of this service, the health status will never refer to the cluster state, only to a single instance.
         * @summary Check HTTP Server Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAlive(options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.isAlive(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MetadataApi.isAlive']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint returns a HTTP 200 status code when Ory Hydra is up running and the environment dependencies (e.g. the database) are responsive as well.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of Ory Hydra, the health status will never refer to the cluster state, only to a single instance.
         * @summary Check HTTP Server and Database Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isReady(options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.isReady(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['MetadataApi.isReady']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * MetadataApi - factory interface
 * @export
 */
export const MetadataApiFactory = function (configuration, basePath, axios) {
    const localVarFp = MetadataApiFp(configuration);
    return {
        /**
         * This endpoint returns the version of Ory Hydra.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of this service, the version will never refer to the cluster state, only to a single instance.
         * @summary Return Running Software Version.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options) {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a HTTP 200 status code when Ory Hydra is accepting incoming HTTP requests. This status does currently not include checks whether the database connection is working.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of this service, the health status will never refer to the cluster state, only to a single instance.
         * @summary Check HTTP Server Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isAlive(options) {
            return localVarFp.isAlive(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns a HTTP 200 status code when Ory Hydra is up running and the environment dependencies (e.g. the database) are responsive as well.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of Ory Hydra, the health status will never refer to the cluster state, only to a single instance.
         * @summary Check HTTP Server and Database Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        isReady(options) {
            return localVarFp.isReady(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * MetadataApi - object-oriented interface
 * @export
 * @class MetadataApi
 * @extends {BaseAPI}
 */
export class MetadataApi extends BaseAPI {
    /**
     * This endpoint returns the version of Ory Hydra.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of this service, the version will never refer to the cluster state, only to a single instance.
     * @summary Return Running Software Version.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    getVersion(options) {
        return MetadataApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint returns a HTTP 200 status code when Ory Hydra is accepting incoming HTTP requests. This status does currently not include checks whether the database connection is working.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of this service, the health status will never refer to the cluster state, only to a single instance.
     * @summary Check HTTP Server Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    isAlive(options) {
        return MetadataApiFp(this.configuration).isAlive(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint returns a HTTP 200 status code when Ory Hydra is up running and the environment dependencies (e.g. the database) are responsive as well.  If the service supports TLS Edge Termination, this endpoint does not require the `X-Forwarded-Proto` header to be set.  Be aware that if you are running multiple nodes of Ory Hydra, the health status will never refer to the cluster state, only to a single instance.
     * @summary Check HTTP Server and Database Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetadataApi
     */
    isReady(options) {
        return MetadataApiFp(this.configuration).isReady(options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * OAuth2Api - axios parameter creator
 * @export
 */
export const OAuth2ApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  This endpoint tells Ory that the subject has authorized the OAuth 2.0 client to access resources on his/her behalf. The consent provider includes additional information, such as session data for access and ID tokens, and if the consent request should be used as basis for future requests.  The response contains a redirect URL which the consent provider should redirect the user-agent to.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
         * @summary Accept OAuth 2.0 Consent Request
         * @param {string} consentChallenge OAuth 2.0 Consent Request Challenge
         * @param {AcceptOAuth2ConsentRequest} [acceptOAuth2ConsentRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOAuth2ConsentRequest: (consentChallenge_1, acceptOAuth2ConsentRequest_1, ...args_1) => __awaiter(this, [consentChallenge_1, acceptOAuth2ConsentRequest_1, ...args_1], void 0, function* (consentChallenge, acceptOAuth2ConsentRequest, options = {}) {
            // verify required parameter 'consentChallenge' is not null or undefined
            assertParamExists('acceptOAuth2ConsentRequest', 'consentChallenge', consentChallenge);
            const localVarPath = `/admin/oauth2/auth/requests/consent/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (consentChallenge !== undefined) {
                localVarQueryParameter['consent_challenge'] = consentChallenge;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(acceptOAuth2ConsentRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.  This endpoint tells Ory that the subject has successfully authenticated and includes additional information such as the subject\'s ID and if Ory should remember the subject\'s subject agent for future authentication attempts by setting a cookie.  The response contains a redirect URL which the login provider should redirect the user-agent to.
         * @summary Accept OAuth 2.0 Login Request
         * @param {string} loginChallenge OAuth 2.0 Login Request Challenge
         * @param {AcceptOAuth2LoginRequest} [acceptOAuth2LoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOAuth2LoginRequest: (loginChallenge_1, acceptOAuth2LoginRequest_1, ...args_1) => __awaiter(this, [loginChallenge_1, acceptOAuth2LoginRequest_1, ...args_1], void 0, function* (loginChallenge, acceptOAuth2LoginRequest, options = {}) {
            // verify required parameter 'loginChallenge' is not null or undefined
            assertParamExists('acceptOAuth2LoginRequest', 'loginChallenge', loginChallenge);
            const localVarPath = `/admin/oauth2/auth/requests/login/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (loginChallenge !== undefined) {
                localVarQueryParameter['login_challenge'] = loginChallenge;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(acceptOAuth2LoginRequest, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * When a user or an application requests Ory OAuth 2.0 to remove the session state of a subject, this endpoint is used to confirm that logout request.  The response contains a redirect URL which the consent provider should redirect the user-agent to.
         * @summary Accept OAuth 2.0 Session Logout Request
         * @param {string} logoutChallenge OAuth 2.0 Logout Request Challenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOAuth2LogoutRequest: (logoutChallenge_1, ...args_1) => __awaiter(this, [logoutChallenge_1, ...args_1], void 0, function* (logoutChallenge, options = {}) {
            // verify required parameter 'logoutChallenge' is not null or undefined
            assertParamExists('acceptOAuth2LogoutRequest', 'logoutChallenge', logoutChallenge);
            const localVarPath = `/admin/oauth2/auth/requests/logout/accept`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logoutChallenge !== undefined) {
                localVarQueryParameter['logout_challenge'] = logoutChallenge;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Create a new OAuth 2.0 client. If you pass `client_secret` the secret is used, otherwise a random secret is generated. The secret is echoed in the response. It is not possible to retrieve it later on.
         * @summary Create OAuth 2.0 Client
         * @param {OAuth2Client} oAuth2Client OAuth 2.0 Client Request Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOAuth2Client: (oAuth2Client_1, ...args_1) => __awaiter(this, [oAuth2Client_1, ...args_1], void 0, function* (oAuth2Client, options = {}) {
            // verify required parameter 'oAuth2Client' is not null or undefined
            assertParamExists('createOAuth2Client', 'oAuth2Client', oAuth2Client);
            const localVarPath = `/admin/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(oAuth2Client, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Delete an existing OAuth 2.0 Client by its ID.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.  Make sure that this endpoint is well protected and only callable by first-party components.
         * @summary Delete OAuth 2.0 Client
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOAuth2Client: (id_1, ...args_1) => __awaiter(this, [id_1, ...args_1], void 0, function* (id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOAuth2Client', 'id', id);
            const localVarPath = `/admin/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint deletes OAuth2 access tokens issued to an OAuth 2.0 Client from the database.
         * @summary Delete OAuth 2.0 Access Tokens from specific OAuth 2.0 Client
         * @param {string} clientId OAuth 2.0 Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOAuth2Token: (clientId_1, ...args_1) => __awaiter(this, [clientId_1, ...args_1], void 0, function* (clientId, options = {}) {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('deleteOAuth2Token', 'clientId', clientId);
            const localVarPath = `/admin/oauth2/tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use this endpoint to delete trusted JWT Bearer Grant Type Issuer. The ID is the one returned when you created the trust relationship.  Once deleted, the associated issuer will no longer be able to perform the JSON Web Token (JWT) Profile for OAuth 2.0 Client Authentication and Authorization Grant.
         * @summary Delete Trusted OAuth2 JWT Bearer Grant Type Issuer
         * @param {string} id The id of the desired grant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrustedOAuth2JwtGrantIssuer: (id_1, ...args_1) => __awaiter(this, [id_1, ...args_1], void 0, function* (id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTrustedOAuth2JwtGrantIssuer', 'id', id);
            const localVarPath = `/admin/trust/grants/jwt-bearer/issuers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Get an OAuth 2.0 client by its ID. This endpoint never returns the client secret.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Get an OAuth 2.0 Client
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2Client: (id_1, ...args_1) => __awaiter(this, [id_1, ...args_1], void 0, function* (id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOAuth2Client', 'id', id);
            const localVarPath = `/admin/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
         * @summary Get OAuth 2.0 Consent Request
         * @param {string} consentChallenge OAuth 2.0 Consent Request Challenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2ConsentRequest: (consentChallenge_1, ...args_1) => __awaiter(this, [consentChallenge_1, ...args_1], void 0, function* (consentChallenge, options = {}) {
            // verify required parameter 'consentChallenge' is not null or undefined
            assertParamExists('getOAuth2ConsentRequest', 'consentChallenge', consentChallenge);
            const localVarPath = `/admin/oauth2/auth/requests/consent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (consentChallenge !== undefined) {
                localVarQueryParameter['consent_challenge'] = consentChallenge;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  Per default, the login provider is Ory itself. You may use a different login provider which needs to be a web-app you write and host, and it must be able to authenticate (\"show the subject a login screen\") a subject (in OAuth2 the proper name for subject is \"resource owner\").  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.
         * @summary Get OAuth 2.0 Login Request
         * @param {string} loginChallenge OAuth 2.0 Login Request Challenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2LoginRequest: (loginChallenge_1, ...args_1) => __awaiter(this, [loginChallenge_1, ...args_1], void 0, function* (loginChallenge, options = {}) {
            // verify required parameter 'loginChallenge' is not null or undefined
            assertParamExists('getOAuth2LoginRequest', 'loginChallenge', loginChallenge);
            const localVarPath = `/admin/oauth2/auth/requests/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (loginChallenge !== undefined) {
                localVarQueryParameter['login_challenge'] = loginChallenge;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use this endpoint to fetch an Ory OAuth 2.0 logout request.
         * @summary Get OAuth 2.0 Session Logout Request
         * @param {string} logoutChallenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2LogoutRequest: (logoutChallenge_1, ...args_1) => __awaiter(this, [logoutChallenge_1, ...args_1], void 0, function* (logoutChallenge, options = {}) {
            // verify required parameter 'logoutChallenge' is not null or undefined
            assertParamExists('getOAuth2LogoutRequest', 'logoutChallenge', logoutChallenge);
            const localVarPath = `/admin/oauth2/auth/requests/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logoutChallenge !== undefined) {
                localVarQueryParameter['logout_challenge'] = logoutChallenge;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use this endpoint to get a trusted JWT Bearer Grant Type Issuer. The ID is the one returned when you created the trust relationship.
         * @summary Get Trusted OAuth2 JWT Bearer Grant Type Issuer
         * @param {string} id The id of the desired grant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrustedOAuth2JwtGrantIssuer: (id_1, ...args_1) => __awaiter(this, [id_1, ...args_1], void 0, function* (id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTrustedOAuth2JwtGrantIssuer', 'id', id);
            const localVarPath = `/admin/trust/grants/jwt-bearer/issuers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * The introspection endpoint allows to check if a token (both refresh and access) is active or not. An active token is neither expired nor revoked. If a token is active, additional information on the token will be included. You can set additional data for a token by setting `session.access_token` during the consent flow.
         * @summary Introspect OAuth2 Access and Refresh Tokens
         * @param {string} token The string value of the token. For access tokens, this is the \\\&quot;access_token\\\&quot; value returned from the token endpoint defined in OAuth 2.0. For refresh tokens, this is the \\\&quot;refresh_token\\\&quot; value returned.
         * @param {string} [scope] An optional, space separated list of required scopes. If the access token was not granted one of the scopes, the result of active will be false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        introspectOAuth2Token: (token_1, scope_1, ...args_1) => __awaiter(this, [token_1, scope_1, ...args_1], void 0, function* (token, scope, options = {}) {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('introspectOAuth2Token', 'token', token);
            const localVarPath = `/admin/oauth2/introspect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new URLSearchParams();
            if (scope !== undefined) {
                localVarFormParams.set('scope', scope);
            }
            if (token !== undefined) {
                localVarFormParams.set('token', token);
            }
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams.toString();
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint lists all clients in the database, and never returns client secrets. As a default it lists the first 100 clients.
         * @summary List OAuth 2.0 Clients
         * @param {number} [pageSize] Items per Page  This is the number of items per page to return. For details on pagination please head over to the [pagination documentation](https://www.ory.sh/docs/ecosystem/api-design#pagination).
         * @param {string} [pageToken] Next Page Token  The next page token. For details on pagination please head over to the [pagination documentation](https://www.ory.sh/docs/ecosystem/api-design#pagination).
         * @param {string} [clientName] The name of the clients to filter by.
         * @param {string} [owner] The owner of the clients to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOAuth2Clients: (pageSize_1, pageToken_1, clientName_1, owner_1, ...args_1) => __awaiter(this, [pageSize_1, pageToken_1, clientName_1, owner_1, ...args_1], void 0, function* (pageSize, pageToken, clientName, owner, options = {}) {
            const localVarPath = `/admin/clients`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }
            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }
            if (clientName !== undefined) {
                localVarQueryParameter['client_name'] = clientName;
            }
            if (owner !== undefined) {
                localVarQueryParameter['owner'] = owner;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint lists all subject\'s granted consent sessions, including client and granted scope. If the subject is unknown or has not granted any consent sessions yet, the endpoint returns an empty JSON array with status code 200 OK.
         * @summary List OAuth 2.0 Consent Sessions of a Subject
         * @param {string} subject The subject to list the consent sessions for.
         * @param {number} [pageSize] Items per Page  This is the number of items per page to return. For details on pagination please head over to the [pagination documentation](https://www.ory.sh/docs/ecosystem/api-design#pagination).
         * @param {string} [pageToken] Next Page Token  The next page token. For details on pagination please head over to the [pagination documentation](https://www.ory.sh/docs/ecosystem/api-design#pagination).
         * @param {string} [loginSessionId] The login session id to list the consent sessions for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOAuth2ConsentSessions: (subject_1, pageSize_1, pageToken_1, loginSessionId_1, ...args_1) => __awaiter(this, [subject_1, pageSize_1, pageToken_1, loginSessionId_1, ...args_1], void 0, function* (subject, pageSize, pageToken, loginSessionId, options = {}) {
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('listOAuth2ConsentSessions', 'subject', subject);
            const localVarPath = `/admin/oauth2/auth/sessions/consent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }
            if (pageToken !== undefined) {
                localVarQueryParameter['page_token'] = pageToken;
            }
            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }
            if (loginSessionId !== undefined) {
                localVarQueryParameter['login_session_id'] = loginSessionId;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use this endpoint to list all trusted JWT Bearer Grant Type Issuers.
         * @summary List Trusted OAuth2 JWT Bearer Grant Type Issuers
         * @param {number} [maxItems]
         * @param {number} [defaultItems]
         * @param {string} [issuer] If optional \&quot;issuer\&quot; is supplied, only jwt-bearer grants with this issuer will be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrustedOAuth2JwtGrantIssuers: (maxItems_1, defaultItems_1, issuer_1, ...args_1) => __awaiter(this, [maxItems_1, defaultItems_1, issuer_1, ...args_1], void 0, function* (maxItems, defaultItems, issuer, options = {}) {
            const localVarPath = `/admin/trust/grants/jwt-bearer/issuers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (maxItems !== undefined) {
                localVarQueryParameter['MaxItems'] = maxItems;
            }
            if (defaultItems !== undefined) {
                localVarQueryParameter['DefaultItems'] = defaultItems;
            }
            if (issuer !== undefined) {
                localVarQueryParameter['issuer'] = issuer;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use open source libraries to perform OAuth 2.0 and OpenID Connect available for any programming language. You can find a list of libraries at https://oauth.net/code/  The Ory SDK is not yet able to this endpoint properly.
         * @summary OAuth 2.0 Authorize Endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuth2Authorize: (...args_1) => __awaiter(this, [...args_1], void 0, function* (options = {}) {
            const localVarPath = `/oauth2/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use open source libraries to perform OAuth 2.0 and OpenID Connect available for any programming language. You can find a list of libraries here https://oauth.net/code/  The Ory SDK is not yet able to this endpoint properly.
         * @summary The OAuth 2.0 Token Endpoint
         * @param {string} grantType
         * @param {string} [clientId]
         * @param {string} [code]
         * @param {string} [redirectUri]
         * @param {string} [refreshToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth2TokenExchange: (grantType_1, clientId_1, code_1, redirectUri_1, refreshToken_1, ...args_1) => __awaiter(this, [grantType_1, clientId_1, code_1, redirectUri_1, refreshToken_1, ...args_1], void 0, function* (grantType, clientId, code, redirectUri, refreshToken, options = {}) {
            // verify required parameter 'grantType' is not null or undefined
            assertParamExists('oauth2TokenExchange', 'grantType', grantType);
            const localVarPath = `/oauth2/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new URLSearchParams();
            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);
            // authentication oauth2 required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration);
            if (clientId !== undefined) {
                localVarFormParams.set('client_id', clientId);
            }
            if (code !== undefined) {
                localVarFormParams.set('code', code);
            }
            if (grantType !== undefined) {
                localVarFormParams.set('grant_type', grantType);
            }
            if (redirectUri !== undefined) {
                localVarFormParams.set('redirect_uri', redirectUri);
            }
            if (refreshToken !== undefined) {
                localVarFormParams.set('refresh_token', refreshToken);
            }
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams.toString();
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Patch an existing OAuth 2.0 Client using JSON Patch. If you pass `client_secret` the secret will be updated and returned via the API. This is the only time you will be able to retrieve the client secret, so write it down and keep it safe.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Patch OAuth 2.0 Client
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {Array<JsonPatch>} jsonPatch OAuth 2.0 Client JSON Patch Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOAuth2Client: (id_1, jsonPatch_1, ...args_1) => __awaiter(this, [id_1, jsonPatch_1, ...args_1], void 0, function* (id, jsonPatch, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchOAuth2Client', 'id', id);
            // verify required parameter 'jsonPatch' is not null or undefined
            assertParamExists('patchOAuth2Client', 'jsonPatch', jsonPatch);
            const localVarPath = `/admin/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(jsonPatch, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  This endpoint tells Ory that the subject has not authorized the OAuth 2.0 client to access resources on his/her behalf. The consent provider must include a reason why the consent was not granted.  The response contains a redirect URL which the consent provider should redirect the user-agent to.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
         * @summary Reject OAuth 2.0 Consent Request
         * @param {string} consentChallenge OAuth 2.0 Consent Request Challenge
         * @param {RejectOAuth2Request} [rejectOAuth2Request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectOAuth2ConsentRequest: (consentChallenge_1, rejectOAuth2Request_1, ...args_1) => __awaiter(this, [consentChallenge_1, rejectOAuth2Request_1, ...args_1], void 0, function* (consentChallenge, rejectOAuth2Request, options = {}) {
            // verify required parameter 'consentChallenge' is not null or undefined
            assertParamExists('rejectOAuth2ConsentRequest', 'consentChallenge', consentChallenge);
            const localVarPath = `/admin/oauth2/auth/requests/consent/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (consentChallenge !== undefined) {
                localVarQueryParameter['consent_challenge'] = consentChallenge;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(rejectOAuth2Request, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.  This endpoint tells Ory that the subject has not authenticated and includes a reason why the authentication was denied.  The response contains a redirect URL which the login provider should redirect the user-agent to.
         * @summary Reject OAuth 2.0 Login Request
         * @param {string} loginChallenge OAuth 2.0 Login Request Challenge
         * @param {RejectOAuth2Request} [rejectOAuth2Request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectOAuth2LoginRequest: (loginChallenge_1, rejectOAuth2Request_1, ...args_1) => __awaiter(this, [loginChallenge_1, rejectOAuth2Request_1, ...args_1], void 0, function* (loginChallenge, rejectOAuth2Request, options = {}) {
            // verify required parameter 'loginChallenge' is not null or undefined
            assertParamExists('rejectOAuth2LoginRequest', 'loginChallenge', loginChallenge);
            const localVarPath = `/admin/oauth2/auth/requests/login/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (loginChallenge !== undefined) {
                localVarQueryParameter['login_challenge'] = loginChallenge;
            }
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(rejectOAuth2Request, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * When a user or an application requests Ory OAuth 2.0 to remove the session state of a subject, this endpoint is used to deny that logout request. No HTTP request body is required.  The response is empty as the logout provider has to chose what action to perform next.
         * @summary Reject OAuth 2.0 Session Logout Request
         * @param {string} logoutChallenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectOAuth2LogoutRequest: (logoutChallenge_1, ...args_1) => __awaiter(this, [logoutChallenge_1, ...args_1], void 0, function* (logoutChallenge, options = {}) {
            // verify required parameter 'logoutChallenge' is not null or undefined
            assertParamExists('rejectOAuth2LogoutRequest', 'logoutChallenge', logoutChallenge);
            const localVarPath = `/admin/oauth2/auth/requests/logout/reject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (logoutChallenge !== undefined) {
                localVarQueryParameter['logout_challenge'] = logoutChallenge;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint revokes a subject\'s granted consent sessions and invalidates all associated OAuth 2.0 Access Tokens. You may also only revoke sessions for a specific OAuth 2.0 Client ID.
         * @summary Revoke OAuth 2.0 Consent Sessions of a Subject
         * @param {string} subject OAuth 2.0 Consent Subject  The subject whose consent sessions should be deleted.
         * @param {string} [client] OAuth 2.0 Client ID  If set, deletes only those consent sessions that have been granted to the specified OAuth 2.0 Client ID.
         * @param {boolean} [all] Revoke All Consent Sessions  If set to &#x60;true&#x60; deletes all consent sessions by the Subject that have been granted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOAuth2ConsentSessions: (subject_1, client_1, all_1, ...args_1) => __awaiter(this, [subject_1, client_1, all_1, ...args_1], void 0, function* (subject, client, all, options = {}) {
            // verify required parameter 'subject' is not null or undefined
            assertParamExists('revokeOAuth2ConsentSessions', 'subject', subject);
            const localVarPath = `/admin/oauth2/auth/sessions/consent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }
            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }
            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint invalidates authentication sessions. After revoking the authentication session(s), the subject has to re-authenticate at the Ory OAuth2 Provider. This endpoint does not invalidate any tokens.  If you send the subject in a query param, all authentication sessions that belong to that subject are revoked. No OpenID Connect Front- or Back-channel logout is performed in this case.  Alternatively, you can send a SessionID via `sid` query param, in which case, only the session that is connected to that SessionID is revoked. OpenID Connect Back-channel logout is performed in this case.
         * @summary Revokes OAuth 2.0 Login Sessions by either a Subject or a SessionID
         * @param {string} [subject] OAuth 2.0 Subject  The subject to revoke authentication sessions for.
         * @param {string} [sid] OAuth 2.0 Subject  The subject to revoke authentication sessions for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOAuth2LoginSessions: (subject_1, sid_1, ...args_1) => __awaiter(this, [subject_1, sid_1, ...args_1], void 0, function* (subject, sid, options = {}) {
            const localVarPath = `/admin/oauth2/auth/sessions/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            if (subject !== undefined) {
                localVarQueryParameter['subject'] = subject;
            }
            if (sid !== undefined) {
                localVarQueryParameter['sid'] = sid;
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Revoking a token (both access and refresh) means that the tokens will be invalid. A revoked access token can no longer be used to make access requests, and a revoked refresh token can no longer be used to refresh an access token. Revoking a refresh token also invalidates the access token that was created with it. A token may only be revoked by the client the token was generated for.
         * @summary Revoke OAuth 2.0 Access or Refresh Token
         * @param {string} token
         * @param {string} [clientId]
         * @param {string} [clientSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOAuth2Token: (token_1, clientId_1, clientSecret_1, ...args_1) => __awaiter(this, [token_1, clientId_1, clientSecret_1, ...args_1], void 0, function* (token, clientId, clientSecret, options = {}) {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('revokeOAuth2Token', 'token', token);
            const localVarPath = `/oauth2/revoke`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new URLSearchParams();
            // authentication basic required
            // http basic authentication required
            setBasicAuthToObject(localVarRequestOptions, configuration);
            // authentication oauth2 required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration);
            if (clientId !== undefined) {
                localVarFormParams.set('client_id', clientId);
            }
            if (clientSecret !== undefined) {
                localVarFormParams.set('client_secret', clientSecret);
            }
            if (token !== undefined) {
                localVarFormParams.set('token', token);
            }
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams.toString();
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Replaces an existing OAuth 2.0 Client with the payload you send. If you pass `client_secret` the secret is used, otherwise the existing secret is used.  If set, the secret is echoed in the response. It is not possible to retrieve it later on.  OAuth 2.0 Clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Set OAuth 2.0 Client
         * @param {string} id OAuth 2.0 Client ID
         * @param {OAuth2Client} oAuth2Client OAuth 2.0 Client Request Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOAuth2Client: (id_1, oAuth2Client_1, ...args_1) => __awaiter(this, [id_1, oAuth2Client_1, ...args_1], void 0, function* (id, oAuth2Client, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOAuth2Client', 'id', id);
            // verify required parameter 'oAuth2Client' is not null or undefined
            assertParamExists('setOAuth2Client', 'oAuth2Client', oAuth2Client);
            const localVarPath = `/admin/clients/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(oAuth2Client, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Set lifespans of different token types issued for this OAuth 2.0 client. Does not modify other fields.
         * @summary Set OAuth2 Client Token Lifespans
         * @param {string} id OAuth 2.0 Client ID
         * @param {OAuth2ClientTokenLifespans} [oAuth2ClientTokenLifespans]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOAuth2ClientLifespans: (id_1, oAuth2ClientTokenLifespans_1, ...args_1) => __awaiter(this, [id_1, oAuth2ClientTokenLifespans_1, ...args_1], void 0, function* (id, oAuth2ClientTokenLifespans, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOAuth2ClientLifespans', 'id', id);
            const localVarPath = `/admin/clients/{id}/lifespans`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(oAuth2ClientTokenLifespans, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Use this endpoint to establish a trust relationship for a JWT issuer to perform JSON Web Token (JWT) Profile for OAuth 2.0 Client Authentication and Authorization Grants [RFC7523](https://datatracker.ietf.org/doc/html/rfc7523).
         * @summary Trust OAuth2 JWT Bearer Grant Type Issuer
         * @param {TrustOAuth2JwtGrantIssuer} [trustOAuth2JwtGrantIssuer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trustOAuth2JwtGrantIssuer: (trustOAuth2JwtGrantIssuer_1, ...args_1) => __awaiter(this, [trustOAuth2JwtGrantIssuer_1, ...args_1], void 0, function* (trustOAuth2JwtGrantIssuer, options = {}) {
            const localVarPath = `/admin/trust/grants/jwt-bearer/issuers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(trustOAuth2JwtGrantIssuer, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * OAuth2Api - functional programming interface
 * @export
 */
export const OAuth2ApiFp = function (configuration) {
    const localVarAxiosParamCreator = OAuth2ApiAxiosParamCreator(configuration);
    return {
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  This endpoint tells Ory that the subject has authorized the OAuth 2.0 client to access resources on his/her behalf. The consent provider includes additional information, such as session data for access and ID tokens, and if the consent request should be used as basis for future requests.  The response contains a redirect URL which the consent provider should redirect the user-agent to.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
         * @summary Accept OAuth 2.0 Consent Request
         * @param {string} consentChallenge OAuth 2.0 Consent Request Challenge
         * @param {AcceptOAuth2ConsentRequest} [acceptOAuth2ConsentRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOAuth2ConsentRequest(consentChallenge, acceptOAuth2ConsentRequest, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.acceptOAuth2ConsentRequest(consentChallenge, acceptOAuth2ConsentRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.acceptOAuth2ConsentRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.  This endpoint tells Ory that the subject has successfully authenticated and includes additional information such as the subject\'s ID and if Ory should remember the subject\'s subject agent for future authentication attempts by setting a cookie.  The response contains a redirect URL which the login provider should redirect the user-agent to.
         * @summary Accept OAuth 2.0 Login Request
         * @param {string} loginChallenge OAuth 2.0 Login Request Challenge
         * @param {AcceptOAuth2LoginRequest} [acceptOAuth2LoginRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOAuth2LoginRequest(loginChallenge, acceptOAuth2LoginRequest, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.acceptOAuth2LoginRequest(loginChallenge, acceptOAuth2LoginRequest, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.acceptOAuth2LoginRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * When a user or an application requests Ory OAuth 2.0 to remove the session state of a subject, this endpoint is used to confirm that logout request.  The response contains a redirect URL which the consent provider should redirect the user-agent to.
         * @summary Accept OAuth 2.0 Session Logout Request
         * @param {string} logoutChallenge OAuth 2.0 Logout Request Challenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOAuth2LogoutRequest(logoutChallenge, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.acceptOAuth2LogoutRequest(logoutChallenge, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.acceptOAuth2LogoutRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Create a new OAuth 2.0 client. If you pass `client_secret` the secret is used, otherwise a random secret is generated. The secret is echoed in the response. It is not possible to retrieve it later on.
         * @summary Create OAuth 2.0 Client
         * @param {OAuth2Client} oAuth2Client OAuth 2.0 Client Request Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOAuth2Client(oAuth2Client, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createOAuth2Client(oAuth2Client, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.createOAuth2Client']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Delete an existing OAuth 2.0 Client by its ID.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.  Make sure that this endpoint is well protected and only callable by first-party components.
         * @summary Delete OAuth 2.0 Client
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOAuth2Client(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteOAuth2Client(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.deleteOAuth2Client']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint deletes OAuth2 access tokens issued to an OAuth 2.0 Client from the database.
         * @summary Delete OAuth 2.0 Access Tokens from specific OAuth 2.0 Client
         * @param {string} clientId OAuth 2.0 Client ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOAuth2Token(clientId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteOAuth2Token(clientId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.deleteOAuth2Token']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use this endpoint to delete trusted JWT Bearer Grant Type Issuer. The ID is the one returned when you created the trust relationship.  Once deleted, the associated issuer will no longer be able to perform the JSON Web Token (JWT) Profile for OAuth 2.0 Client Authentication and Authorization Grant.
         * @summary Delete Trusted OAuth2 JWT Bearer Grant Type Issuer
         * @param {string} id The id of the desired grant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrustedOAuth2JwtGrantIssuer(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteTrustedOAuth2JwtGrantIssuer(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.deleteTrustedOAuth2JwtGrantIssuer']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Get an OAuth 2.0 client by its ID. This endpoint never returns the client secret.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Get an OAuth 2.0 Client
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2Client(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOAuth2Client(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.getOAuth2Client']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
         * @summary Get OAuth 2.0 Consent Request
         * @param {string} consentChallenge OAuth 2.0 Consent Request Challenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2ConsentRequest(consentChallenge, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOAuth2ConsentRequest(consentChallenge, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.getOAuth2ConsentRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  Per default, the login provider is Ory itself. You may use a different login provider which needs to be a web-app you write and host, and it must be able to authenticate (\"show the subject a login screen\") a subject (in OAuth2 the proper name for subject is \"resource owner\").  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.
         * @summary Get OAuth 2.0 Login Request
         * @param {string} loginChallenge OAuth 2.0 Login Request Challenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2LoginRequest(loginChallenge, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOAuth2LoginRequest(loginChallenge, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.getOAuth2LoginRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use this endpoint to fetch an Ory OAuth 2.0 logout request.
         * @summary Get OAuth 2.0 Session Logout Request
         * @param {string} logoutChallenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2LogoutRequest(logoutChallenge, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOAuth2LogoutRequest(logoutChallenge, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.getOAuth2LogoutRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use this endpoint to get a trusted JWT Bearer Grant Type Issuer. The ID is the one returned when you created the trust relationship.
         * @summary Get Trusted OAuth2 JWT Bearer Grant Type Issuer
         * @param {string} id The id of the desired grant
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrustedOAuth2JwtGrantIssuer(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getTrustedOAuth2JwtGrantIssuer(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.getTrustedOAuth2JwtGrantIssuer']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * The introspection endpoint allows to check if a token (both refresh and access) is active or not. An active token is neither expired nor revoked. If a token is active, additional information on the token will be included. You can set additional data for a token by setting `session.access_token` during the consent flow.
         * @summary Introspect OAuth2 Access and Refresh Tokens
         * @param {string} token The string value of the token. For access tokens, this is the \\\&quot;access_token\\\&quot; value returned from the token endpoint defined in OAuth 2.0. For refresh tokens, this is the \\\&quot;refresh_token\\\&quot; value returned.
         * @param {string} [scope] An optional, space separated list of required scopes. If the access token was not granted one of the scopes, the result of active will be false.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        introspectOAuth2Token(token, scope, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.introspectOAuth2Token(token, scope, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.introspectOAuth2Token']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint lists all clients in the database, and never returns client secrets. As a default it lists the first 100 clients.
         * @summary List OAuth 2.0 Clients
         * @param {number} [pageSize] Items per Page  This is the number of items per page to return. For details on pagination please head over to the [pagination documentation](https://www.ory.sh/docs/ecosystem/api-design#pagination).
         * @param {string} [pageToken] Next Page Token  The next page token. For details on pagination please head over to the [pagination documentation](https://www.ory.sh/docs/ecosystem/api-design#pagination).
         * @param {string} [clientName] The name of the clients to filter by.
         * @param {string} [owner] The owner of the clients to filter by.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOAuth2Clients(pageSize, pageToken, clientName, owner, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listOAuth2Clients(pageSize, pageToken, clientName, owner, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.listOAuth2Clients']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint lists all subject\'s granted consent sessions, including client and granted scope. If the subject is unknown or has not granted any consent sessions yet, the endpoint returns an empty JSON array with status code 200 OK.
         * @summary List OAuth 2.0 Consent Sessions of a Subject
         * @param {string} subject The subject to list the consent sessions for.
         * @param {number} [pageSize] Items per Page  This is the number of items per page to return. For details on pagination please head over to the [pagination documentation](https://www.ory.sh/docs/ecosystem/api-design#pagination).
         * @param {string} [pageToken] Next Page Token  The next page token. For details on pagination please head over to the [pagination documentation](https://www.ory.sh/docs/ecosystem/api-design#pagination).
         * @param {string} [loginSessionId] The login session id to list the consent sessions for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOAuth2ConsentSessions(subject, pageSize, pageToken, loginSessionId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listOAuth2ConsentSessions(subject, pageSize, pageToken, loginSessionId, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.listOAuth2ConsentSessions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use this endpoint to list all trusted JWT Bearer Grant Type Issuers.
         * @summary List Trusted OAuth2 JWT Bearer Grant Type Issuers
         * @param {number} [maxItems]
         * @param {number} [defaultItems]
         * @param {string} [issuer] If optional \&quot;issuer\&quot; is supplied, only jwt-bearer grants with this issuer will be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrustedOAuth2JwtGrantIssuers(maxItems, defaultItems, issuer, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.listTrustedOAuth2JwtGrantIssuers(maxItems, defaultItems, issuer, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.listTrustedOAuth2JwtGrantIssuers']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use open source libraries to perform OAuth 2.0 and OpenID Connect available for any programming language. You can find a list of libraries at https://oauth.net/code/  The Ory SDK is not yet able to this endpoint properly.
         * @summary OAuth 2.0 Authorize Endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuth2Authorize(options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.oAuth2Authorize(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.oAuth2Authorize']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use open source libraries to perform OAuth 2.0 and OpenID Connect available for any programming language. You can find a list of libraries here https://oauth.net/code/  The Ory SDK is not yet able to this endpoint properly.
         * @summary The OAuth 2.0 Token Endpoint
         * @param {string} grantType
         * @param {string} [clientId]
         * @param {string} [code]
         * @param {string} [redirectUri]
         * @param {string} [refreshToken]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth2TokenExchange(grantType, clientId, code, redirectUri, refreshToken, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.oauth2TokenExchange(grantType, clientId, code, redirectUri, refreshToken, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.oauth2TokenExchange']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Patch an existing OAuth 2.0 Client using JSON Patch. If you pass `client_secret` the secret will be updated and returned via the API. This is the only time you will be able to retrieve the client secret, so write it down and keep it safe.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Patch OAuth 2.0 Client
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {Array<JsonPatch>} jsonPatch OAuth 2.0 Client JSON Patch Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOAuth2Client(id, jsonPatch, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.patchOAuth2Client(id, jsonPatch, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.patchOAuth2Client']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  This endpoint tells Ory that the subject has not authorized the OAuth 2.0 client to access resources on his/her behalf. The consent provider must include a reason why the consent was not granted.  The response contains a redirect URL which the consent provider should redirect the user-agent to.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
         * @summary Reject OAuth 2.0 Consent Request
         * @param {string} consentChallenge OAuth 2.0 Consent Request Challenge
         * @param {RejectOAuth2Request} [rejectOAuth2Request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectOAuth2ConsentRequest(consentChallenge, rejectOAuth2Request, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.rejectOAuth2ConsentRequest(consentChallenge, rejectOAuth2Request, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.rejectOAuth2ConsentRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.  This endpoint tells Ory that the subject has not authenticated and includes a reason why the authentication was denied.  The response contains a redirect URL which the login provider should redirect the user-agent to.
         * @summary Reject OAuth 2.0 Login Request
         * @param {string} loginChallenge OAuth 2.0 Login Request Challenge
         * @param {RejectOAuth2Request} [rejectOAuth2Request]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectOAuth2LoginRequest(loginChallenge, rejectOAuth2Request, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.rejectOAuth2LoginRequest(loginChallenge, rejectOAuth2Request, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.rejectOAuth2LoginRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * When a user or an application requests Ory OAuth 2.0 to remove the session state of a subject, this endpoint is used to deny that logout request. No HTTP request body is required.  The response is empty as the logout provider has to chose what action to perform next.
         * @summary Reject OAuth 2.0 Session Logout Request
         * @param {string} logoutChallenge
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectOAuth2LogoutRequest(logoutChallenge, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.rejectOAuth2LogoutRequest(logoutChallenge, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.rejectOAuth2LogoutRequest']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint revokes a subject\'s granted consent sessions and invalidates all associated OAuth 2.0 Access Tokens. You may also only revoke sessions for a specific OAuth 2.0 Client ID.
         * @summary Revoke OAuth 2.0 Consent Sessions of a Subject
         * @param {string} subject OAuth 2.0 Consent Subject  The subject whose consent sessions should be deleted.
         * @param {string} [client] OAuth 2.0 Client ID  If set, deletes only those consent sessions that have been granted to the specified OAuth 2.0 Client ID.
         * @param {boolean} [all] Revoke All Consent Sessions  If set to &#x60;true&#x60; deletes all consent sessions by the Subject that have been granted.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOAuth2ConsentSessions(subject, client, all, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.revokeOAuth2ConsentSessions(subject, client, all, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.revokeOAuth2ConsentSessions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint invalidates authentication sessions. After revoking the authentication session(s), the subject has to re-authenticate at the Ory OAuth2 Provider. This endpoint does not invalidate any tokens.  If you send the subject in a query param, all authentication sessions that belong to that subject are revoked. No OpenID Connect Front- or Back-channel logout is performed in this case.  Alternatively, you can send a SessionID via `sid` query param, in which case, only the session that is connected to that SessionID is revoked. OpenID Connect Back-channel logout is performed in this case.
         * @summary Revokes OAuth 2.0 Login Sessions by either a Subject or a SessionID
         * @param {string} [subject] OAuth 2.0 Subject  The subject to revoke authentication sessions for.
         * @param {string} [sid] OAuth 2.0 Subject  The subject to revoke authentication sessions for.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOAuth2LoginSessions(subject, sid, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.revokeOAuth2LoginSessions(subject, sid, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.revokeOAuth2LoginSessions']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Revoking a token (both access and refresh) means that the tokens will be invalid. A revoked access token can no longer be used to make access requests, and a revoked refresh token can no longer be used to refresh an access token. Revoking a refresh token also invalidates the access token that was created with it. A token may only be revoked by the client the token was generated for.
         * @summary Revoke OAuth 2.0 Access or Refresh Token
         * @param {string} token
         * @param {string} [clientId]
         * @param {string} [clientSecret]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOAuth2Token(token, clientId, clientSecret, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.revokeOAuth2Token(token, clientId, clientSecret, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.revokeOAuth2Token']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Replaces an existing OAuth 2.0 Client with the payload you send. If you pass `client_secret` the secret is used, otherwise the existing secret is used.  If set, the secret is echoed in the response. It is not possible to retrieve it later on.  OAuth 2.0 Clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Set OAuth 2.0 Client
         * @param {string} id OAuth 2.0 Client ID
         * @param {OAuth2Client} oAuth2Client OAuth 2.0 Client Request Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOAuth2Client(id, oAuth2Client, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setOAuth2Client(id, oAuth2Client, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.setOAuth2Client']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Set lifespans of different token types issued for this OAuth 2.0 client. Does not modify other fields.
         * @summary Set OAuth2 Client Token Lifespans
         * @param {string} id OAuth 2.0 Client ID
         * @param {OAuth2ClientTokenLifespans} [oAuth2ClientTokenLifespans]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOAuth2ClientLifespans(id, oAuth2ClientTokenLifespans, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setOAuth2ClientLifespans(id, oAuth2ClientTokenLifespans, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.setOAuth2ClientLifespans']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * Use this endpoint to establish a trust relationship for a JWT issuer to perform JSON Web Token (JWT) Profile for OAuth 2.0 Client Authentication and Authorization Grants [RFC7523](https://datatracker.ietf.org/doc/html/rfc7523).
         * @summary Trust OAuth2 JWT Bearer Grant Type Issuer
         * @param {TrustOAuth2JwtGrantIssuer} [trustOAuth2JwtGrantIssuer]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trustOAuth2JwtGrantIssuer(trustOAuth2JwtGrantIssuer, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.trustOAuth2JwtGrantIssuer(trustOAuth2JwtGrantIssuer, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OAuth2Api.trustOAuth2JwtGrantIssuer']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * OAuth2Api - factory interface
 * @export
 */
export const OAuth2ApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OAuth2ApiFp(configuration);
    return {
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  This endpoint tells Ory that the subject has authorized the OAuth 2.0 client to access resources on his/her behalf. The consent provider includes additional information, such as session data for access and ID tokens, and if the consent request should be used as basis for future requests.  The response contains a redirect URL which the consent provider should redirect the user-agent to.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
         * @summary Accept OAuth 2.0 Consent Request
         * @param {OAuth2ApiAcceptOAuth2ConsentRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOAuth2ConsentRequest(requestParameters, options) {
            return localVarFp.acceptOAuth2ConsentRequest(requestParameters.consentChallenge, requestParameters.acceptOAuth2ConsentRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.  This endpoint tells Ory that the subject has successfully authenticated and includes additional information such as the subject\'s ID and if Ory should remember the subject\'s subject agent for future authentication attempts by setting a cookie.  The response contains a redirect URL which the login provider should redirect the user-agent to.
         * @summary Accept OAuth 2.0 Login Request
         * @param {OAuth2ApiAcceptOAuth2LoginRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOAuth2LoginRequest(requestParameters, options) {
            return localVarFp.acceptOAuth2LoginRequest(requestParameters.loginChallenge, requestParameters.acceptOAuth2LoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * When a user or an application requests Ory OAuth 2.0 to remove the session state of a subject, this endpoint is used to confirm that logout request.  The response contains a redirect URL which the consent provider should redirect the user-agent to.
         * @summary Accept OAuth 2.0 Session Logout Request
         * @param {OAuth2ApiAcceptOAuth2LogoutRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptOAuth2LogoutRequest(requestParameters, options) {
            return localVarFp.acceptOAuth2LogoutRequest(requestParameters.logoutChallenge, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new OAuth 2.0 client. If you pass `client_secret` the secret is used, otherwise a random secret is generated. The secret is echoed in the response. It is not possible to retrieve it later on.
         * @summary Create OAuth 2.0 Client
         * @param {OAuth2ApiCreateOAuth2ClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOAuth2Client(requestParameters, options) {
            return localVarFp.createOAuth2Client(requestParameters.oAuth2Client, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an existing OAuth 2.0 Client by its ID.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.  Make sure that this endpoint is well protected and only callable by first-party components.
         * @summary Delete OAuth 2.0 Client
         * @param {OAuth2ApiDeleteOAuth2ClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOAuth2Client(requestParameters, options) {
            return localVarFp.deleteOAuth2Client(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint deletes OAuth2 access tokens issued to an OAuth 2.0 Client from the database.
         * @summary Delete OAuth 2.0 Access Tokens from specific OAuth 2.0 Client
         * @param {OAuth2ApiDeleteOAuth2TokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOAuth2Token(requestParameters, options) {
            return localVarFp.deleteOAuth2Token(requestParameters.clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to delete trusted JWT Bearer Grant Type Issuer. The ID is the one returned when you created the trust relationship.  Once deleted, the associated issuer will no longer be able to perform the JSON Web Token (JWT) Profile for OAuth 2.0 Client Authentication and Authorization Grant.
         * @summary Delete Trusted OAuth2 JWT Bearer Grant Type Issuer
         * @param {OAuth2ApiDeleteTrustedOAuth2JwtGrantIssuerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTrustedOAuth2JwtGrantIssuer(requestParameters, options) {
            return localVarFp.deleteTrustedOAuth2JwtGrantIssuer(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an OAuth 2.0 client by its ID. This endpoint never returns the client secret.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Get an OAuth 2.0 Client
         * @param {OAuth2ApiGetOAuth2ClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2Client(requestParameters, options) {
            return localVarFp.getOAuth2Client(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
         * @summary Get OAuth 2.0 Consent Request
         * @param {OAuth2ApiGetOAuth2ConsentRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2ConsentRequest(requestParameters, options) {
            return localVarFp.getOAuth2ConsentRequest(requestParameters.consentChallenge, options).then((request) => request(axios, basePath));
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  Per default, the login provider is Ory itself. You may use a different login provider which needs to be a web-app you write and host, and it must be able to authenticate (\"show the subject a login screen\") a subject (in OAuth2 the proper name for subject is \"resource owner\").  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.
         * @summary Get OAuth 2.0 Login Request
         * @param {OAuth2ApiGetOAuth2LoginRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2LoginRequest(requestParameters, options) {
            return localVarFp.getOAuth2LoginRequest(requestParameters.loginChallenge, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to fetch an Ory OAuth 2.0 logout request.
         * @summary Get OAuth 2.0 Session Logout Request
         * @param {OAuth2ApiGetOAuth2LogoutRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuth2LogoutRequest(requestParameters, options) {
            return localVarFp.getOAuth2LogoutRequest(requestParameters.logoutChallenge, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to get a trusted JWT Bearer Grant Type Issuer. The ID is the one returned when you created the trust relationship.
         * @summary Get Trusted OAuth2 JWT Bearer Grant Type Issuer
         * @param {OAuth2ApiGetTrustedOAuth2JwtGrantIssuerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrustedOAuth2JwtGrantIssuer(requestParameters, options) {
            return localVarFp.getTrustedOAuth2JwtGrantIssuer(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * The introspection endpoint allows to check if a token (both refresh and access) is active or not. An active token is neither expired nor revoked. If a token is active, additional information on the token will be included. You can set additional data for a token by setting `session.access_token` during the consent flow.
         * @summary Introspect OAuth2 Access and Refresh Tokens
         * @param {OAuth2ApiIntrospectOAuth2TokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        introspectOAuth2Token(requestParameters, options) {
            return localVarFp.introspectOAuth2Token(requestParameters.token, requestParameters.scope, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint lists all clients in the database, and never returns client secrets. As a default it lists the first 100 clients.
         * @summary List OAuth 2.0 Clients
         * @param {OAuth2ApiListOAuth2ClientsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOAuth2Clients(requestParameters = {}, options) {
            return localVarFp.listOAuth2Clients(requestParameters.pageSize, requestParameters.pageToken, requestParameters.clientName, requestParameters.owner, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint lists all subject\'s granted consent sessions, including client and granted scope. If the subject is unknown or has not granted any consent sessions yet, the endpoint returns an empty JSON array with status code 200 OK.
         * @summary List OAuth 2.0 Consent Sessions of a Subject
         * @param {OAuth2ApiListOAuth2ConsentSessionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOAuth2ConsentSessions(requestParameters, options) {
            return localVarFp.listOAuth2ConsentSessions(requestParameters.subject, requestParameters.pageSize, requestParameters.pageToken, requestParameters.loginSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to list all trusted JWT Bearer Grant Type Issuers.
         * @summary List Trusted OAuth2 JWT Bearer Grant Type Issuers
         * @param {OAuth2ApiListTrustedOAuth2JwtGrantIssuersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTrustedOAuth2JwtGrantIssuers(requestParameters = {}, options) {
            return localVarFp.listTrustedOAuth2JwtGrantIssuers(requestParameters.maxItems, requestParameters.defaultItems, requestParameters.issuer, options).then((request) => request(axios, basePath));
        },
        /**
         * Use open source libraries to perform OAuth 2.0 and OpenID Connect available for any programming language. You can find a list of libraries at https://oauth.net/code/  The Ory SDK is not yet able to this endpoint properly.
         * @summary OAuth 2.0 Authorize Endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oAuth2Authorize(options) {
            return localVarFp.oAuth2Authorize(options).then((request) => request(axios, basePath));
        },
        /**
         * Use open source libraries to perform OAuth 2.0 and OpenID Connect available for any programming language. You can find a list of libraries here https://oauth.net/code/  The Ory SDK is not yet able to this endpoint properly.
         * @summary The OAuth 2.0 Token Endpoint
         * @param {OAuth2ApiOauth2TokenExchangeRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        oauth2TokenExchange(requestParameters, options) {
            return localVarFp.oauth2TokenExchange(requestParameters.grantType, requestParameters.clientId, requestParameters.code, requestParameters.redirectUri, requestParameters.refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * Patch an existing OAuth 2.0 Client using JSON Patch. If you pass `client_secret` the secret will be updated and returned via the API. This is the only time you will be able to retrieve the client secret, so write it down and keep it safe.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Patch OAuth 2.0 Client
         * @param {OAuth2ApiPatchOAuth2ClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchOAuth2Client(requestParameters, options) {
            return localVarFp.patchOAuth2Client(requestParameters.id, requestParameters.jsonPatch, options).then((request) => request(axios, basePath));
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  This endpoint tells Ory that the subject has not authorized the OAuth 2.0 client to access resources on his/her behalf. The consent provider must include a reason why the consent was not granted.  The response contains a redirect URL which the consent provider should redirect the user-agent to.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
         * @summary Reject OAuth 2.0 Consent Request
         * @param {OAuth2ApiRejectOAuth2ConsentRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectOAuth2ConsentRequest(requestParameters, options) {
            return localVarFp.rejectOAuth2ConsentRequest(requestParameters.consentChallenge, requestParameters.rejectOAuth2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.  This endpoint tells Ory that the subject has not authenticated and includes a reason why the authentication was denied.  The response contains a redirect URL which the login provider should redirect the user-agent to.
         * @summary Reject OAuth 2.0 Login Request
         * @param {OAuth2ApiRejectOAuth2LoginRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectOAuth2LoginRequest(requestParameters, options) {
            return localVarFp.rejectOAuth2LoginRequest(requestParameters.loginChallenge, requestParameters.rejectOAuth2Request, options).then((request) => request(axios, basePath));
        },
        /**
         * When a user or an application requests Ory OAuth 2.0 to remove the session state of a subject, this endpoint is used to deny that logout request. No HTTP request body is required.  The response is empty as the logout provider has to chose what action to perform next.
         * @summary Reject OAuth 2.0 Session Logout Request
         * @param {OAuth2ApiRejectOAuth2LogoutRequestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectOAuth2LogoutRequest(requestParameters, options) {
            return localVarFp.rejectOAuth2LogoutRequest(requestParameters.logoutChallenge, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint revokes a subject\'s granted consent sessions and invalidates all associated OAuth 2.0 Access Tokens. You may also only revoke sessions for a specific OAuth 2.0 Client ID.
         * @summary Revoke OAuth 2.0 Consent Sessions of a Subject
         * @param {OAuth2ApiRevokeOAuth2ConsentSessionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOAuth2ConsentSessions(requestParameters, options) {
            return localVarFp.revokeOAuth2ConsentSessions(requestParameters.subject, requestParameters.client, requestParameters.all, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint invalidates authentication sessions. After revoking the authentication session(s), the subject has to re-authenticate at the Ory OAuth2 Provider. This endpoint does not invalidate any tokens.  If you send the subject in a query param, all authentication sessions that belong to that subject are revoked. No OpenID Connect Front- or Back-channel logout is performed in this case.  Alternatively, you can send a SessionID via `sid` query param, in which case, only the session that is connected to that SessionID is revoked. OpenID Connect Back-channel logout is performed in this case.
         * @summary Revokes OAuth 2.0 Login Sessions by either a Subject or a SessionID
         * @param {OAuth2ApiRevokeOAuth2LoginSessionsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOAuth2LoginSessions(requestParameters = {}, options) {
            return localVarFp.revokeOAuth2LoginSessions(requestParameters.subject, requestParameters.sid, options).then((request) => request(axios, basePath));
        },
        /**
         * Revoking a token (both access and refresh) means that the tokens will be invalid. A revoked access token can no longer be used to make access requests, and a revoked refresh token can no longer be used to refresh an access token. Revoking a refresh token also invalidates the access token that was created with it. A token may only be revoked by the client the token was generated for.
         * @summary Revoke OAuth 2.0 Access or Refresh Token
         * @param {OAuth2ApiRevokeOAuth2TokenRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOAuth2Token(requestParameters, options) {
            return localVarFp.revokeOAuth2Token(requestParameters.token, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces an existing OAuth 2.0 Client with the payload you send. If you pass `client_secret` the secret is used, otherwise the existing secret is used.  If set, the secret is echoed in the response. It is not possible to retrieve it later on.  OAuth 2.0 Clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Set OAuth 2.0 Client
         * @param {OAuth2ApiSetOAuth2ClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOAuth2Client(requestParameters, options) {
            return localVarFp.setOAuth2Client(requestParameters.id, requestParameters.oAuth2Client, options).then((request) => request(axios, basePath));
        },
        /**
         * Set lifespans of different token types issued for this OAuth 2.0 client. Does not modify other fields.
         * @summary Set OAuth2 Client Token Lifespans
         * @param {OAuth2ApiSetOAuth2ClientLifespansRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOAuth2ClientLifespans(requestParameters, options) {
            return localVarFp.setOAuth2ClientLifespans(requestParameters.id, requestParameters.oAuth2ClientTokenLifespans, options).then((request) => request(axios, basePath));
        },
        /**
         * Use this endpoint to establish a trust relationship for a JWT issuer to perform JSON Web Token (JWT) Profile for OAuth 2.0 Client Authentication and Authorization Grants [RFC7523](https://datatracker.ietf.org/doc/html/rfc7523).
         * @summary Trust OAuth2 JWT Bearer Grant Type Issuer
         * @param {OAuth2ApiTrustOAuth2JwtGrantIssuerRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        trustOAuth2JwtGrantIssuer(requestParameters = {}, options) {
            return localVarFp.trustOAuth2JwtGrantIssuer(requestParameters.trustOAuth2JwtGrantIssuer, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OAuth2Api - object-oriented interface
 * @export
 * @class OAuth2Api
 * @extends {BaseAPI}
 */
export class OAuth2Api extends BaseAPI {
    /**
     * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  This endpoint tells Ory that the subject has authorized the OAuth 2.0 client to access resources on his/her behalf. The consent provider includes additional information, such as session data for access and ID tokens, and if the consent request should be used as basis for future requests.  The response contains a redirect URL which the consent provider should redirect the user-agent to.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
     * @summary Accept OAuth 2.0 Consent Request
     * @param {OAuth2ApiAcceptOAuth2ConsentRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    acceptOAuth2ConsentRequest(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).acceptOAuth2ConsentRequest(requestParameters.consentChallenge, requestParameters.acceptOAuth2ConsentRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.  This endpoint tells Ory that the subject has successfully authenticated and includes additional information such as the subject\'s ID and if Ory should remember the subject\'s subject agent for future authentication attempts by setting a cookie.  The response contains a redirect URL which the login provider should redirect the user-agent to.
     * @summary Accept OAuth 2.0 Login Request
     * @param {OAuth2ApiAcceptOAuth2LoginRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    acceptOAuth2LoginRequest(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).acceptOAuth2LoginRequest(requestParameters.loginChallenge, requestParameters.acceptOAuth2LoginRequest, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * When a user or an application requests Ory OAuth 2.0 to remove the session state of a subject, this endpoint is used to confirm that logout request.  The response contains a redirect URL which the consent provider should redirect the user-agent to.
     * @summary Accept OAuth 2.0 Session Logout Request
     * @param {OAuth2ApiAcceptOAuth2LogoutRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    acceptOAuth2LogoutRequest(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).acceptOAuth2LogoutRequest(requestParameters.logoutChallenge, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Create a new OAuth 2.0 client. If you pass `client_secret` the secret is used, otherwise a random secret is generated. The secret is echoed in the response. It is not possible to retrieve it later on.
     * @summary Create OAuth 2.0 Client
     * @param {OAuth2ApiCreateOAuth2ClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    createOAuth2Client(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).createOAuth2Client(requestParameters.oAuth2Client, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Delete an existing OAuth 2.0 Client by its ID.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.  Make sure that this endpoint is well protected and only callable by first-party components.
     * @summary Delete OAuth 2.0 Client
     * @param {OAuth2ApiDeleteOAuth2ClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    deleteOAuth2Client(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).deleteOAuth2Client(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint deletes OAuth2 access tokens issued to an OAuth 2.0 Client from the database.
     * @summary Delete OAuth 2.0 Access Tokens from specific OAuth 2.0 Client
     * @param {OAuth2ApiDeleteOAuth2TokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    deleteOAuth2Token(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).deleteOAuth2Token(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use this endpoint to delete trusted JWT Bearer Grant Type Issuer. The ID is the one returned when you created the trust relationship.  Once deleted, the associated issuer will no longer be able to perform the JSON Web Token (JWT) Profile for OAuth 2.0 Client Authentication and Authorization Grant.
     * @summary Delete Trusted OAuth2 JWT Bearer Grant Type Issuer
     * @param {OAuth2ApiDeleteTrustedOAuth2JwtGrantIssuerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    deleteTrustedOAuth2JwtGrantIssuer(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).deleteTrustedOAuth2JwtGrantIssuer(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Get an OAuth 2.0 client by its ID. This endpoint never returns the client secret.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
     * @summary Get an OAuth 2.0 Client
     * @param {OAuth2ApiGetOAuth2ClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    getOAuth2Client(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).getOAuth2Client(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
     * @summary Get OAuth 2.0 Consent Request
     * @param {OAuth2ApiGetOAuth2ConsentRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    getOAuth2ConsentRequest(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).getOAuth2ConsentRequest(requestParameters.consentChallenge, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  Per default, the login provider is Ory itself. You may use a different login provider which needs to be a web-app you write and host, and it must be able to authenticate (\"show the subject a login screen\") a subject (in OAuth2 the proper name for subject is \"resource owner\").  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.
     * @summary Get OAuth 2.0 Login Request
     * @param {OAuth2ApiGetOAuth2LoginRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    getOAuth2LoginRequest(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).getOAuth2LoginRequest(requestParameters.loginChallenge, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use this endpoint to fetch an Ory OAuth 2.0 logout request.
     * @summary Get OAuth 2.0 Session Logout Request
     * @param {OAuth2ApiGetOAuth2LogoutRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    getOAuth2LogoutRequest(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).getOAuth2LogoutRequest(requestParameters.logoutChallenge, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use this endpoint to get a trusted JWT Bearer Grant Type Issuer. The ID is the one returned when you created the trust relationship.
     * @summary Get Trusted OAuth2 JWT Bearer Grant Type Issuer
     * @param {OAuth2ApiGetTrustedOAuth2JwtGrantIssuerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    getTrustedOAuth2JwtGrantIssuer(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).getTrustedOAuth2JwtGrantIssuer(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * The introspection endpoint allows to check if a token (both refresh and access) is active or not. An active token is neither expired nor revoked. If a token is active, additional information on the token will be included. You can set additional data for a token by setting `session.access_token` during the consent flow.
     * @summary Introspect OAuth2 Access and Refresh Tokens
     * @param {OAuth2ApiIntrospectOAuth2TokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    introspectOAuth2Token(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).introspectOAuth2Token(requestParameters.token, requestParameters.scope, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint lists all clients in the database, and never returns client secrets. As a default it lists the first 100 clients.
     * @summary List OAuth 2.0 Clients
     * @param {OAuth2ApiListOAuth2ClientsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    listOAuth2Clients(requestParameters = {}, options) {
        return OAuth2ApiFp(this.configuration).listOAuth2Clients(requestParameters.pageSize, requestParameters.pageToken, requestParameters.clientName, requestParameters.owner, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint lists all subject\'s granted consent sessions, including client and granted scope. If the subject is unknown or has not granted any consent sessions yet, the endpoint returns an empty JSON array with status code 200 OK.
     * @summary List OAuth 2.0 Consent Sessions of a Subject
     * @param {OAuth2ApiListOAuth2ConsentSessionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    listOAuth2ConsentSessions(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).listOAuth2ConsentSessions(requestParameters.subject, requestParameters.pageSize, requestParameters.pageToken, requestParameters.loginSessionId, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use this endpoint to list all trusted JWT Bearer Grant Type Issuers.
     * @summary List Trusted OAuth2 JWT Bearer Grant Type Issuers
     * @param {OAuth2ApiListTrustedOAuth2JwtGrantIssuersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    listTrustedOAuth2JwtGrantIssuers(requestParameters = {}, options) {
        return OAuth2ApiFp(this.configuration).listTrustedOAuth2JwtGrantIssuers(requestParameters.maxItems, requestParameters.defaultItems, requestParameters.issuer, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use open source libraries to perform OAuth 2.0 and OpenID Connect available for any programming language. You can find a list of libraries at https://oauth.net/code/  The Ory SDK is not yet able to this endpoint properly.
     * @summary OAuth 2.0 Authorize Endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    oAuth2Authorize(options) {
        return OAuth2ApiFp(this.configuration).oAuth2Authorize(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use open source libraries to perform OAuth 2.0 and OpenID Connect available for any programming language. You can find a list of libraries here https://oauth.net/code/  The Ory SDK is not yet able to this endpoint properly.
     * @summary The OAuth 2.0 Token Endpoint
     * @param {OAuth2ApiOauth2TokenExchangeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    oauth2TokenExchange(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).oauth2TokenExchange(requestParameters.grantType, requestParameters.clientId, requestParameters.code, requestParameters.redirectUri, requestParameters.refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Patch an existing OAuth 2.0 Client using JSON Patch. If you pass `client_secret` the secret will be updated and returned via the API. This is the only time you will be able to retrieve the client secret, so write it down and keep it safe.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
     * @summary Patch OAuth 2.0 Client
     * @param {OAuth2ApiPatchOAuth2ClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    patchOAuth2Client(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).patchOAuth2Client(requestParameters.id, requestParameters.jsonPatch, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell Ory now about it. If the subject authenticated, he/she must now be asked if the OAuth 2.0 Client which initiated the flow should be allowed to access the resources on the subject\'s behalf.  The consent challenge is appended to the consent provider\'s URL to which the subject\'s user-agent (browser) is redirected to. The consent provider uses that challenge to fetch information on the OAuth2 request and then tells Ory if the subject accepted or rejected the request.  This endpoint tells Ory that the subject has not authorized the OAuth 2.0 client to access resources on his/her behalf. The consent provider must include a reason why the consent was not granted.  The response contains a redirect URL which the consent provider should redirect the user-agent to.  The default consent provider is available via the Ory Managed Account Experience. To customize the consent provider, please head over to the OAuth 2.0 documentation.
     * @summary Reject OAuth 2.0 Consent Request
     * @param {OAuth2ApiRejectOAuth2ConsentRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    rejectOAuth2ConsentRequest(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).rejectOAuth2ConsentRequest(requestParameters.consentChallenge, requestParameters.rejectOAuth2Request, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * When an authorization code, hybrid, or implicit OAuth 2.0 Flow is initiated, Ory asks the login provider to authenticate the subject and then tell the Ory OAuth2 Service about it.  The authentication challenge is appended to the login provider URL to which the subject\'s user-agent (browser) is redirected to. The login provider uses that challenge to fetch information on the OAuth2 request and then accept or reject the requested authentication process.  This endpoint tells Ory that the subject has not authenticated and includes a reason why the authentication was denied.  The response contains a redirect URL which the login provider should redirect the user-agent to.
     * @summary Reject OAuth 2.0 Login Request
     * @param {OAuth2ApiRejectOAuth2LoginRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    rejectOAuth2LoginRequest(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).rejectOAuth2LoginRequest(requestParameters.loginChallenge, requestParameters.rejectOAuth2Request, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * When a user or an application requests Ory OAuth 2.0 to remove the session state of a subject, this endpoint is used to deny that logout request. No HTTP request body is required.  The response is empty as the logout provider has to chose what action to perform next.
     * @summary Reject OAuth 2.0 Session Logout Request
     * @param {OAuth2ApiRejectOAuth2LogoutRequestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    rejectOAuth2LogoutRequest(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).rejectOAuth2LogoutRequest(requestParameters.logoutChallenge, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint revokes a subject\'s granted consent sessions and invalidates all associated OAuth 2.0 Access Tokens. You may also only revoke sessions for a specific OAuth 2.0 Client ID.
     * @summary Revoke OAuth 2.0 Consent Sessions of a Subject
     * @param {OAuth2ApiRevokeOAuth2ConsentSessionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    revokeOAuth2ConsentSessions(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).revokeOAuth2ConsentSessions(requestParameters.subject, requestParameters.client, requestParameters.all, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint invalidates authentication sessions. After revoking the authentication session(s), the subject has to re-authenticate at the Ory OAuth2 Provider. This endpoint does not invalidate any tokens.  If you send the subject in a query param, all authentication sessions that belong to that subject are revoked. No OpenID Connect Front- or Back-channel logout is performed in this case.  Alternatively, you can send a SessionID via `sid` query param, in which case, only the session that is connected to that SessionID is revoked. OpenID Connect Back-channel logout is performed in this case.
     * @summary Revokes OAuth 2.0 Login Sessions by either a Subject or a SessionID
     * @param {OAuth2ApiRevokeOAuth2LoginSessionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    revokeOAuth2LoginSessions(requestParameters = {}, options) {
        return OAuth2ApiFp(this.configuration).revokeOAuth2LoginSessions(requestParameters.subject, requestParameters.sid, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Revoking a token (both access and refresh) means that the tokens will be invalid. A revoked access token can no longer be used to make access requests, and a revoked refresh token can no longer be used to refresh an access token. Revoking a refresh token also invalidates the access token that was created with it. A token may only be revoked by the client the token was generated for.
     * @summary Revoke OAuth 2.0 Access or Refresh Token
     * @param {OAuth2ApiRevokeOAuth2TokenRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    revokeOAuth2Token(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).revokeOAuth2Token(requestParameters.token, requestParameters.clientId, requestParameters.clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Replaces an existing OAuth 2.0 Client with the payload you send. If you pass `client_secret` the secret is used, otherwise the existing secret is used.  If set, the secret is echoed in the response. It is not possible to retrieve it later on.  OAuth 2.0 Clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
     * @summary Set OAuth 2.0 Client
     * @param {OAuth2ApiSetOAuth2ClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    setOAuth2Client(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).setOAuth2Client(requestParameters.id, requestParameters.oAuth2Client, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Set lifespans of different token types issued for this OAuth 2.0 client. Does not modify other fields.
     * @summary Set OAuth2 Client Token Lifespans
     * @param {OAuth2ApiSetOAuth2ClientLifespansRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    setOAuth2ClientLifespans(requestParameters, options) {
        return OAuth2ApiFp(this.configuration).setOAuth2ClientLifespans(requestParameters.id, requestParameters.oAuth2ClientTokenLifespans, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Use this endpoint to establish a trust relationship for a JWT issuer to perform JSON Web Token (JWT) Profile for OAuth 2.0 Client Authentication and Authorization Grants [RFC7523](https://datatracker.ietf.org/doc/html/rfc7523).
     * @summary Trust OAuth2 JWT Bearer Grant Type Issuer
     * @param {OAuth2ApiTrustOAuth2JwtGrantIssuerRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuth2Api
     */
    trustOAuth2JwtGrantIssuer(requestParameters = {}, options) {
        return OAuth2ApiFp(this.configuration).trustOAuth2JwtGrantIssuer(requestParameters.trustOAuth2JwtGrantIssuer, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * OidcApi - axios parameter creator
 * @export
 */
export const OidcApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * This endpoint behaves like the administrative counterpart (`createOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol. This feature needs to be enabled in the configuration. This endpoint is disabled by default. It can be enabled by an administrator.  Please note that using this endpoint you are not able to choose the `client_secret` nor the `client_id` as those values will be server generated when specifying `token_endpoint_auth_method` as `client_secret_basic` or `client_secret_post`.  The `client_secret` will be returned in the response and you will not be able to retrieve it later on. Write the secret down and keep it somewhere safe.
         * @summary Register OAuth2 Client using OpenID Dynamic Client Registration
         * @param {OAuth2Client} oAuth2Client Dynamic Client Registration Request Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOidcDynamicClient: (oAuth2Client_1, ...args_1) => __awaiter(this, [oAuth2Client_1, ...args_1], void 0, function* (oAuth2Client, options = {}) {
            // verify required parameter 'oAuth2Client' is not null or undefined
            assertParamExists('createOidcDynamicClient', 'oAuth2Client', oAuth2Client);
            const localVarPath = `/oauth2/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(oAuth2Client, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint creates a verifiable credential that attests that the user authenticated with the provided access token owns a certain public/private key pair.  More information can be found at https://openid.net/specs/openid-connect-userinfo-vc-1_0.html.
         * @summary Issues a Verifiable Credential
         * @param {CreateVerifiableCredentialRequestBody} [createVerifiableCredentialRequestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVerifiableCredential: (createVerifiableCredentialRequestBody_1, ...args_1) => __awaiter(this, [createVerifiableCredentialRequestBody_1, ...args_1], void 0, function* (createVerifiableCredentialRequestBody, options = {}) {
            const localVarPath = `/credentials`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(createVerifiableCredentialRequestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint behaves like the administrative counterpart (`deleteOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol. This feature needs to be enabled in the configuration. This endpoint is disabled by default. It can be enabled by an administrator.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Delete OAuth 2.0 Client using the OpenID Dynamic Client Registration Management Protocol
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOidcDynamicClient: (id_1, ...args_1) => __awaiter(this, [id_1, ...args_1], void 0, function* (id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOidcDynamicClient', 'id', id);
            const localVarPath = `/oauth2/register/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * A mechanism for an OpenID Connect Relying Party to discover the End-User\'s OpenID Provider and obtain information needed to interact with it, including its OAuth 2.0 endpoint locations.  Popular libraries for OpenID Connect clients include oidc-client-js (JavaScript), go-oidc (Golang), and others. For a full list of clients go here: https://openid.net/developers/certified/
         * @summary OpenID Connect Discovery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discoverOidcConfiguration: (...args_1) => __awaiter(this, [...args_1], void 0, function* (options = {}) {
            const localVarPath = `/.well-known/openid-configuration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint behaves like the administrative counterpart (`getOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.
         * @summary Get OAuth2 Client using OpenID Dynamic Client Registration
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOidcDynamicClient: (id_1, ...args_1) => __awaiter(this, [id_1, ...args_1], void 0, function* (id, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOidcDynamicClient', 'id', id);
            const localVarPath = `/oauth2/register/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint returns the payload of the ID Token, including `session.id_token` values, of the provided OAuth 2.0 Access Token\'s consent request.  In the case of authentication error, a WWW-Authenticate header might be set in the response with more information about the error. See [the spec](https://datatracker.ietf.org/doc/html/rfc6750#section-3) for more details about header format.
         * @summary OpenID Connect Userinfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOidcUserInfo: (...args_1) => __awaiter(this, [...args_1], void 0, function* (options = {}) {
            const localVarPath = `/userinfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication oauth2 required
            // oauth required
            yield setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration);
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint initiates and completes user logout at the Ory OAuth2 & OpenID provider and initiates OpenID Connect Front- / Back-channel logout:  https://openid.net/specs/openid-connect-frontchannel-1_0.html https://openid.net/specs/openid-connect-backchannel-1_0.html  Back-channel logout is performed asynchronously and does not affect logout flow.
         * @summary OpenID Connect Front- and Back-channel Enabled Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOidcSession: (...args_1) => __awaiter(this, [...args_1], void 0, function* (options = {}) {
            const localVarPath = `/oauth2/sessions/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * This endpoint behaves like the administrative counterpart (`setOAuth2Client`) but is capable of facing the public internet directly to be used by third parties. It implements the OpenID Connect Dynamic Client Registration Protocol.  This feature is disabled per default. It can be enabled by a system administrator.  If you pass `client_secret` the secret is used, otherwise the existing secret is used. If set, the secret is echoed in the response. It is not possible to retrieve it later on.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Set OAuth2 Client using OpenID Dynamic Client Registration
         * @param {string} id OAuth 2.0 Client ID
         * @param {OAuth2Client} oAuth2Client OAuth 2.0 Client Request Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOidcDynamicClient: (id_1, oAuth2Client_1, ...args_1) => __awaiter(this, [id_1, oAuth2Client_1, ...args_1], void 0, function* (id, oAuth2Client, options = {}) {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setOidcDynamicClient', 'id', id);
            // verify required parameter 'oAuth2Client' is not null or undefined
            assertParamExists('setOidcDynamicClient', 'oAuth2Client', oAuth2Client);
            const localVarPath = `/oauth2/register/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PUT' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearer required
            // http bearer authentication required
            yield setBearerAuthToObject(localVarHeaderParameter, configuration);
            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = serializeDataIfNeeded(oAuth2Client, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * OidcApi - functional programming interface
 * @export
 */
export const OidcApiFp = function (configuration) {
    const localVarAxiosParamCreator = OidcApiAxiosParamCreator(configuration);
    return {
        /**
         * This endpoint behaves like the administrative counterpart (`createOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol. This feature needs to be enabled in the configuration. This endpoint is disabled by default. It can be enabled by an administrator.  Please note that using this endpoint you are not able to choose the `client_secret` nor the `client_id` as those values will be server generated when specifying `token_endpoint_auth_method` as `client_secret_basic` or `client_secret_post`.  The `client_secret` will be returned in the response and you will not be able to retrieve it later on. Write the secret down and keep it somewhere safe.
         * @summary Register OAuth2 Client using OpenID Dynamic Client Registration
         * @param {OAuth2Client} oAuth2Client Dynamic Client Registration Request Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOidcDynamicClient(oAuth2Client, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createOidcDynamicClient(oAuth2Client, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OidcApi.createOidcDynamicClient']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint creates a verifiable credential that attests that the user authenticated with the provided access token owns a certain public/private key pair.  More information can be found at https://openid.net/specs/openid-connect-userinfo-vc-1_0.html.
         * @summary Issues a Verifiable Credential
         * @param {CreateVerifiableCredentialRequestBody} [createVerifiableCredentialRequestBody]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVerifiableCredential(createVerifiableCredentialRequestBody, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createVerifiableCredential(createVerifiableCredentialRequestBody, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OidcApi.createVerifiableCredential']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint behaves like the administrative counterpart (`deleteOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol. This feature needs to be enabled in the configuration. This endpoint is disabled by default. It can be enabled by an administrator.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Delete OAuth 2.0 Client using the OpenID Dynamic Client Registration Management Protocol
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOidcDynamicClient(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteOidcDynamicClient(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OidcApi.deleteOidcDynamicClient']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * A mechanism for an OpenID Connect Relying Party to discover the End-User\'s OpenID Provider and obtain information needed to interact with it, including its OAuth 2.0 endpoint locations.  Popular libraries for OpenID Connect clients include oidc-client-js (JavaScript), go-oidc (Golang), and others. For a full list of clients go here: https://openid.net/developers/certified/
         * @summary OpenID Connect Discovery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discoverOidcConfiguration(options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.discoverOidcConfiguration(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OidcApi.discoverOidcConfiguration']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint behaves like the administrative counterpart (`getOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.
         * @summary Get OAuth2 Client using OpenID Dynamic Client Registration
         * @param {string} id The id of the OAuth 2.0 Client.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOidcDynamicClient(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOidcDynamicClient(id, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OidcApi.getOidcDynamicClient']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint returns the payload of the ID Token, including `session.id_token` values, of the provided OAuth 2.0 Access Token\'s consent request.  In the case of authentication error, a WWW-Authenticate header might be set in the response with more information about the error. See [the spec](https://datatracker.ietf.org/doc/html/rfc6750#section-3) for more details about header format.
         * @summary OpenID Connect Userinfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOidcUserInfo(options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getOidcUserInfo(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OidcApi.getOidcUserInfo']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint initiates and completes user logout at the Ory OAuth2 & OpenID provider and initiates OpenID Connect Front- / Back-channel logout:  https://openid.net/specs/openid-connect-frontchannel-1_0.html https://openid.net/specs/openid-connect-backchannel-1_0.html  Back-channel logout is performed asynchronously and does not affect logout flow.
         * @summary OpenID Connect Front- and Back-channel Enabled Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOidcSession(options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.revokeOidcSession(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OidcApi.revokeOidcSession']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
        /**
         * This endpoint behaves like the administrative counterpart (`setOAuth2Client`) but is capable of facing the public internet directly to be used by third parties. It implements the OpenID Connect Dynamic Client Registration Protocol.  This feature is disabled per default. It can be enabled by a system administrator.  If you pass `client_secret` the secret is used, otherwise the existing secret is used. If set, the secret is echoed in the response. It is not possible to retrieve it later on.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Set OAuth2 Client using OpenID Dynamic Client Registration
         * @param {string} id OAuth 2.0 Client ID
         * @param {OAuth2Client} oAuth2Client OAuth 2.0 Client Request Body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOidcDynamicClient(id, oAuth2Client, options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.setOidcDynamicClient(id, oAuth2Client, options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['OidcApi.setOidcDynamicClient']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * OidcApi - factory interface
 * @export
 */
export const OidcApiFactory = function (configuration, basePath, axios) {
    const localVarFp = OidcApiFp(configuration);
    return {
        /**
         * This endpoint behaves like the administrative counterpart (`createOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol. This feature needs to be enabled in the configuration. This endpoint is disabled by default. It can be enabled by an administrator.  Please note that using this endpoint you are not able to choose the `client_secret` nor the `client_id` as those values will be server generated when specifying `token_endpoint_auth_method` as `client_secret_basic` or `client_secret_post`.  The `client_secret` will be returned in the response and you will not be able to retrieve it later on. Write the secret down and keep it somewhere safe.
         * @summary Register OAuth2 Client using OpenID Dynamic Client Registration
         * @param {OidcApiCreateOidcDynamicClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOidcDynamicClient(requestParameters, options) {
            return localVarFp.createOidcDynamicClient(requestParameters.oAuth2Client, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint creates a verifiable credential that attests that the user authenticated with the provided access token owns a certain public/private key pair.  More information can be found at https://openid.net/specs/openid-connect-userinfo-vc-1_0.html.
         * @summary Issues a Verifiable Credential
         * @param {OidcApiCreateVerifiableCredentialRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVerifiableCredential(requestParameters = {}, options) {
            return localVarFp.createVerifiableCredential(requestParameters.createVerifiableCredentialRequestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint behaves like the administrative counterpart (`deleteOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol. This feature needs to be enabled in the configuration. This endpoint is disabled by default. It can be enabled by an administrator.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Delete OAuth 2.0 Client using the OpenID Dynamic Client Registration Management Protocol
         * @param {OidcApiDeleteOidcDynamicClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOidcDynamicClient(requestParameters, options) {
            return localVarFp.deleteOidcDynamicClient(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * A mechanism for an OpenID Connect Relying Party to discover the End-User\'s OpenID Provider and obtain information needed to interact with it, including its OAuth 2.0 endpoint locations.  Popular libraries for OpenID Connect clients include oidc-client-js (JavaScript), go-oidc (Golang), and others. For a full list of clients go here: https://openid.net/developers/certified/
         * @summary OpenID Connect Discovery
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discoverOidcConfiguration(options) {
            return localVarFp.discoverOidcConfiguration(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint behaves like the administrative counterpart (`getOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.
         * @summary Get OAuth2 Client using OpenID Dynamic Client Registration
         * @param {OidcApiGetOidcDynamicClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOidcDynamicClient(requestParameters, options) {
            return localVarFp.getOidcDynamicClient(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint returns the payload of the ID Token, including `session.id_token` values, of the provided OAuth 2.0 Access Token\'s consent request.  In the case of authentication error, a WWW-Authenticate header might be set in the response with more information about the error. See [the spec](https://datatracker.ietf.org/doc/html/rfc6750#section-3) for more details about header format.
         * @summary OpenID Connect Userinfo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOidcUserInfo(options) {
            return localVarFp.getOidcUserInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint initiates and completes user logout at the Ory OAuth2 & OpenID provider and initiates OpenID Connect Front- / Back-channel logout:  https://openid.net/specs/openid-connect-frontchannel-1_0.html https://openid.net/specs/openid-connect-backchannel-1_0.html  Back-channel logout is performed asynchronously and does not affect logout flow.
         * @summary OpenID Connect Front- and Back-channel Enabled Logout
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revokeOidcSession(options) {
            return localVarFp.revokeOidcSession(options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint behaves like the administrative counterpart (`setOAuth2Client`) but is capable of facing the public internet directly to be used by third parties. It implements the OpenID Connect Dynamic Client Registration Protocol.  This feature is disabled per default. It can be enabled by a system administrator.  If you pass `client_secret` the secret is used, otherwise the existing secret is used. If set, the secret is echoed in the response. It is not possible to retrieve it later on.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
         * @summary Set OAuth2 Client using OpenID Dynamic Client Registration
         * @param {OidcApiSetOidcDynamicClientRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOidcDynamicClient(requestParameters, options) {
            return localVarFp.setOidcDynamicClient(requestParameters.id, requestParameters.oAuth2Client, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * OidcApi - object-oriented interface
 * @export
 * @class OidcApi
 * @extends {BaseAPI}
 */
export class OidcApi extends BaseAPI {
    /**
     * This endpoint behaves like the administrative counterpart (`createOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol. This feature needs to be enabled in the configuration. This endpoint is disabled by default. It can be enabled by an administrator.  Please note that using this endpoint you are not able to choose the `client_secret` nor the `client_id` as those values will be server generated when specifying `token_endpoint_auth_method` as `client_secret_basic` or `client_secret_post`.  The `client_secret` will be returned in the response and you will not be able to retrieve it later on. Write the secret down and keep it somewhere safe.
     * @summary Register OAuth2 Client using OpenID Dynamic Client Registration
     * @param {OidcApiCreateOidcDynamicClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcApi
     */
    createOidcDynamicClient(requestParameters, options) {
        return OidcApiFp(this.configuration).createOidcDynamicClient(requestParameters.oAuth2Client, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint creates a verifiable credential that attests that the user authenticated with the provided access token owns a certain public/private key pair.  More information can be found at https://openid.net/specs/openid-connect-userinfo-vc-1_0.html.
     * @summary Issues a Verifiable Credential
     * @param {OidcApiCreateVerifiableCredentialRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcApi
     */
    createVerifiableCredential(requestParameters = {}, options) {
        return OidcApiFp(this.configuration).createVerifiableCredential(requestParameters.createVerifiableCredentialRequestBody, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint behaves like the administrative counterpart (`deleteOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol. This feature needs to be enabled in the configuration. This endpoint is disabled by default. It can be enabled by an administrator.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
     * @summary Delete OAuth 2.0 Client using the OpenID Dynamic Client Registration Management Protocol
     * @param {OidcApiDeleteOidcDynamicClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcApi
     */
    deleteOidcDynamicClient(requestParameters, options) {
        return OidcApiFp(this.configuration).deleteOidcDynamicClient(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * A mechanism for an OpenID Connect Relying Party to discover the End-User\'s OpenID Provider and obtain information needed to interact with it, including its OAuth 2.0 endpoint locations.  Popular libraries for OpenID Connect clients include oidc-client-js (JavaScript), go-oidc (Golang), and others. For a full list of clients go here: https://openid.net/developers/certified/
     * @summary OpenID Connect Discovery
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcApi
     */
    discoverOidcConfiguration(options) {
        return OidcApiFp(this.configuration).discoverOidcConfiguration(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint behaves like the administrative counterpart (`getOAuth2Client`) but is capable of facing the public internet directly and can be used in self-service. It implements the OpenID Connect Dynamic Client Registration Protocol.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.
     * @summary Get OAuth2 Client using OpenID Dynamic Client Registration
     * @param {OidcApiGetOidcDynamicClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcApi
     */
    getOidcDynamicClient(requestParameters, options) {
        return OidcApiFp(this.configuration).getOidcDynamicClient(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint returns the payload of the ID Token, including `session.id_token` values, of the provided OAuth 2.0 Access Token\'s consent request.  In the case of authentication error, a WWW-Authenticate header might be set in the response with more information about the error. See [the spec](https://datatracker.ietf.org/doc/html/rfc6750#section-3) for more details about header format.
     * @summary OpenID Connect Userinfo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcApi
     */
    getOidcUserInfo(options) {
        return OidcApiFp(this.configuration).getOidcUserInfo(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint initiates and completes user logout at the Ory OAuth2 & OpenID provider and initiates OpenID Connect Front- / Back-channel logout:  https://openid.net/specs/openid-connect-frontchannel-1_0.html https://openid.net/specs/openid-connect-backchannel-1_0.html  Back-channel logout is performed asynchronously and does not affect logout flow.
     * @summary OpenID Connect Front- and Back-channel Enabled Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcApi
     */
    revokeOidcSession(options) {
        return OidcApiFp(this.configuration).revokeOidcSession(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * This endpoint behaves like the administrative counterpart (`setOAuth2Client`) but is capable of facing the public internet directly to be used by third parties. It implements the OpenID Connect Dynamic Client Registration Protocol.  This feature is disabled per default. It can be enabled by a system administrator.  If you pass `client_secret` the secret is used, otherwise the existing secret is used. If set, the secret is echoed in the response. It is not possible to retrieve it later on.  To use this endpoint, you will need to present the client\'s authentication credentials. If the OAuth2 Client uses the Token Endpoint Authentication Method `client_secret_post`, you need to present the client secret in the URL query. If it uses `client_secret_basic`, present the Client ID and the Client Secret in the Authorization header.  OAuth 2.0 clients are used to perform OAuth 2.0 and OpenID Connect flows. Usually, OAuth 2.0 clients are generated for applications which want to consume your OAuth 2.0 or OpenID Connect capabilities.
     * @summary Set OAuth2 Client using OpenID Dynamic Client Registration
     * @param {OidcApiSetOidcDynamicClientRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OidcApi
     */
    setOidcDynamicClient(requestParameters, options) {
        return OidcApiFp(this.configuration).setOidcDynamicClient(requestParameters.id, requestParameters.oAuth2Client, options).then((request) => request(this.axios, this.basePath));
    }
}
/**
 * WellknownApi - axios parameter creator
 * @export
 */
export const WellknownApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * This endpoint returns JSON Web Keys required to verifying OpenID Connect ID Tokens and, if enabled, OAuth 2.0 JWT Access Tokens. This endpoint can be used with client libraries like [node-jwks-rsa](https://github.com/auth0/node-jwks-rsa) among others.
         * @summary Discover Well-Known JSON Web Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discoverJsonWebKeys: (...args_1) => __awaiter(this, [...args_1], void 0, function* (options = {}) {
            const localVarPath = `/.well-known/jwks.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * WellknownApi - functional programming interface
 * @export
 */
export const WellknownApiFp = function (configuration) {
    const localVarAxiosParamCreator = WellknownApiAxiosParamCreator(configuration);
    return {
        /**
         * This endpoint returns JSON Web Keys required to verifying OpenID Connect ID Tokens and, if enabled, OAuth 2.0 JWT Access Tokens. This endpoint can be used with client libraries like [node-jwks-rsa](https://github.com/auth0/node-jwks-rsa) among others.
         * @summary Discover Well-Known JSON Web Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discoverJsonWebKeys(options) {
            return __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                const localVarAxiosArgs = yield localVarAxiosParamCreator.discoverJsonWebKeys(options);
                const localVarOperationServerIndex = (_a = configuration === null || configuration === void 0 ? void 0 : configuration.serverIndex) !== null && _a !== void 0 ? _a : 0;
                const localVarOperationServerBasePath = (_c = (_b = operationServerMap['WellknownApi.discoverJsonWebKeys']) === null || _b === void 0 ? void 0 : _b[localVarOperationServerIndex]) === null || _c === void 0 ? void 0 : _c.url;
                return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
            });
        },
    };
};
/**
 * WellknownApi - factory interface
 * @export
 */
export const WellknownApiFactory = function (configuration, basePath, axios) {
    const localVarFp = WellknownApiFp(configuration);
    return {
        /**
         * This endpoint returns JSON Web Keys required to verifying OpenID Connect ID Tokens and, if enabled, OAuth 2.0 JWT Access Tokens. This endpoint can be used with client libraries like [node-jwks-rsa](https://github.com/auth0/node-jwks-rsa) among others.
         * @summary Discover Well-Known JSON Web Keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discoverJsonWebKeys(options) {
            return localVarFp.discoverJsonWebKeys(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * WellknownApi - object-oriented interface
 * @export
 * @class WellknownApi
 * @extends {BaseAPI}
 */
export class WellknownApi extends BaseAPI {
    /**
     * This endpoint returns JSON Web Keys required to verifying OpenID Connect ID Tokens and, if enabled, OAuth 2.0 JWT Access Tokens. This endpoint can be used with client libraries like [node-jwks-rsa](https://github.com/auth0/node-jwks-rsa) among others.
     * @summary Discover Well-Known JSON Web Keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WellknownApi
     */
    discoverJsonWebKeys(options) {
        return WellknownApiFp(this.configuration).discoverJsonWebKeys(options).then((request) => request(this.axios, this.basePath));
    }
}
