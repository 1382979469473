import { Role } from './authTypes';
import { IdentityApi, FrontendApi, Configuration as KratosConfig } from '@ory/kratos-client';
import { Configuration as HydraConfig, OAuth2Api } from '@ory/hydra-client';

const hydraPublic = new OAuth2Api(
    new HydraConfig({
        basePath: `${process.env.KRATOS_URL}/api/hydra/public`,
    })
);

const hydraAdmin = new OAuth2Api(
    new HydraConfig({
        basePath: `${process.env.KRATOS_URL}/api/hydra`,
        baseOptions: {
            auth: {
                username: process.env.CLIENT_ID,
                password: process.env.CLIENT_SECRET,
            },
        },
    })
);
const kratosPublic = new FrontendApi(
    new KratosConfig({
        basePath: `${process.env.KRATOS_URL}/api/kratos/public`,
    })
);
const kratosAdmin = new IdentityApi(
    new KratosConfig({
        basePath: `${process.env.KRATOS_URL}/api/kratos`,
        baseOptions: {
            auth: {
                username: process.env.CLIENT_ID,
                password: process.env.CLIENT_SECRET,
            },
        },
    })
);

const removeRole = (roles: Role[], role: Role) => [
    ...roles.filter((r) => !(r.role === role.role && r.tenant === role.tenant)),
];
const addRole = (roles: Role[], role: Role) => [...removeRole(roles, role), role];

export { addRole, removeRole, kratosAdmin, kratosPublic, hydraAdmin, hydraPublic };
