import '@webcomponents/custom-elements';
import { register } from '../utils/webcomponents';

export class BarcodeScannerFriendlyInput extends HTMLElement {
    inputElement;
    debounce;
    debounceTime = 100;
    averageTimeTreshold = 10; //time in ms - for avareage input, no human should be able consistently type under this value
    lastTime: number;
    scannerInput: boolean;
    inputTimes = [];

    constructor() {
        super();
    }

    static get observedAttributes() {
        return ['data-value', 'data-placeholder', 'data-disabled', 'data-type'];
    }

    get value() {
        return this.getAttribute('data-value') || '';
    }

    get placeholder() {
        return this.getAttribute('data-placeholder');
    }

    get inputType() {
        return this.getAttribute('data-type');
    }

    get disabled() {
        return this.getAttribute('data-disabled') === 'true';
    }

    connectedCallback() {
        this.render();
    }

    onKeyDown(e) {
        this.wasKeyDown = true;
    }

    getAverageTime() {
        if (this.inputTimes.length <= 2) {
            return this.averageTimeTreshold * 2;
        }
        return this.inputTimes.reduce((acc, item) => acc + item[0], 0) / this.inputTimes.length;
    }

    onKeyUp(e) {
        if (this.debounce) clearTimeout(this.debounce);

        const delay = this.lastTime ? performance.now() - this.lastTime : 0;
        if (e.key && e.key.length < 4) {
            this.inputTimes.push([delay, e.key]);
        }

        this.debounce = setTimeout(() => {
            this.inputStarted = false;

            if (this.getAverageTime() <= this.averageTimeTreshold) {
                this.onBarcodeInput(e);
            } else {
                this.onInput(e);
            }

            this.inputTimes = [];
            this.lastTime = 0;

            this.scannerInput = false;
        }, this.debounceTime);

        this.inputStarted = true;
        this.lastTime = performance.now();
    }

    onBarcodeInput(e) {
        const inputValue = e.target.value;

        this.dispatchEvent(
            new CustomEvent('scannerinput', {
                detail: { value: inputValue },
            })
        );
    }

    onInput(e) {
        const inputValue = e.target.value;

        this.dispatchEvent(
            new CustomEvent('oninput', {
                detail: { value: inputValue },
            })
        );
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (this.inputElement) {
            if (this.disabled) {
                this.inputElement.setAttribute('disabled', true);
            } else {
                this.inputElement.removeAttribute('disabled');
            }

            this.inputElement.value = this.value;
        }
    }

    render() {
        this.inputElement = document.createElement('input');
        switch (this.inputType) {
            case 'email':
                this.inputElement.setAttribute('type', 'email');
                this.inputElement.setAttribute('inputmode', 'email');
                break;

            case 'number':
                this.inputElement.setAttribute('type', 'email');
                break;

            default:
                this.inputElement.setAttribute('type', 'text');
                break;
        }

        this.inputElement.setAttribute('placeholder', this.placeholder);
        this.inputElement.value = this.value;

        this.inputElement.addEventListener('keyup', this.onKeyUp.bind(this));

        this.appendChild(this.inputElement);
    }
}

register('barcode-scanner-friendly-input', BarcodeScannerFriendlyInput);
